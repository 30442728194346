<template>
  <ul class="el-menu collapsed" v-show="$store.getters.isMenuCollapsed">
    <template v-for="(item, index) in $router.options.routes">
      <li v-if="!item.hidden && !item.leaf" :key="index" style="position: relative" class="el-submenu">

        <el-popover
                placement="right"
                width="180"
                trigger="hover">

          <ul class="el-menu submenu">
            <template v-for="child in item.children">
              <li v-if="!child.hidden" :key="child.path" class="item" @click="$router.push({name: child.name})">
                <i :class="child.meta.icon"></i> {{ getTitle(child.meta.title) }}
              </li>

              <template v-if="child.hidden && child.children && child.children.length > 0">
                <li v-for="grandchild in child.children" v-if="!grandchild.hidden" :key="child.path" class="item" @click="$router.push({name: grandchild.name})">
                  <i :class="grandchild.meta.icon"></i> {{ getTitle(grandchild.meta.title) }}
                </li>
              </template>

            </template>
          </ul>

          <div slot="reference" class="el-submenu__title">
            <i :class="item.meta.icon" class="menu-icon"></i>
          </div>

        </el-popover>


      </li>

      <li v-if="item.leaf && item.children.length > 0" :key="item.children[0].path">
        <div class="el-submenu__title" @click="$router.push({name: item.children[0].name})">
          <i :class="item.children[0].meta.icon" class="menu-icon"></i>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
  export default {
    name: "PopupMenu",
    data: function(){
      return {
        menuOpened: false
      }
    },

    methods: {
      getTitle(key){
        const hasKey = this.$te('router.' + key)

        if (hasKey) {
          return this.$t('router.' + key)
        }

        return key
      }
    }
  }
</script>

<style scoped>
  .el-menu {
    border-width: 0px;
  }

  .menu-icon {
    color: white;
    padding: 5px;
    font-size: large;
  }

  .el-menu.collapsed {
    background-color: #545c64;
    margin-top: 60px;
    width: 100%;
  }


  .el-menu.submenu {
    font-family: Avenir;
    font-size: medium;
    color: #333;
  }

  .el-menu.submenu li {
    cursor: pointer;
    padding: 5px;
    color: #333;
  }

  .el-menu.submenu li:hover {
    background-color: #545c64;
    color: white;
  }

  .el-menu.submenu li i {
    width: 20px;
    height: 20px;
  }

  .el-submenu__title:hover {
    background-color: #00BFD7;
  }
</style>