<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',

  computed: {
    isReconnecting(){
      return this.$store.getters.isReconnecting;
    }
  },


  watch: {
    isReconnecting(value){
      const loading = this.$loading({
        lock: true,
        text: this.$t('app.reconnecting'),
        spinner: 'el-icon-loading',
        fullscreen: true,
        customClass: "loadingClass"
      });

      if (!value)
        loading.close();
    }
  },

  created(){
    this.$store.dispatch('initAuth');
    this.$store.dispatch('initDeviceInfo');
  }
}
</script>

<style>
  @import url("/assets/customfonts/style.css");
  #app {
    font-family:"SF Light";
    background-color: #f2f2f2;
    height: 100vh;
  }
  html, body {
      overflow: hidden;
  }

</style>