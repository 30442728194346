import request from '@/api/auth-service'

export function login(data) {
    return request({
        url: 'login',
        method: "post",
        data
    })
}

export function checkAuth(data){
    return request({
        url: 'user/checkAuth',
        method: 'post',
        data
    })
}

export function getInfo(){
    return request({
        url: 'profile',
        method: 'get'
    })
}

export function changePassword(password) {
    return request({
        url: 'password/change',
        method: "post",
        data: {
            password
        }
    })
}

export function getRoles() {
    return request({
        url: 'user/roles',
        method: "get",
    })
}