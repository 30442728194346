import {
  MUTATION_SET_TOKEN,
  MUTATION_SOCKET_ONCLOSE,
  MUTATION_SOCKET_ONERROR,
  MUTATION_SOCKET_ONMESSAGE,
  MUTATION_SOCKET_ONOPEN,
  MUTATION_SOCKET_RECONNECT,
  MUTATION_SOCKET_RECONNECT_ERROR,
} from "../mutation-types";
import { ERROR_UNAUTHORIZED } from "../error-types";
import Vue from "vue";

/**********************************  Patterns For Request And Response Methods  **********************************
 *
 * pattern for request methods
 * {
 *   method: 'request method name',
 *   data: {
 *     token: 'authentication token',
 *     payload: 'request data or params'
 *   }
 * }
 *
 * pattern for response methods when success
 * {
 *   method: 'action method name',
 *   response: {
 *     data: 'response data',
 *     message: 'success message'
 *   }
 * }
 *
 * pattern for response methods when error occured
 * {
 *   method: 'action method name',
 *   error: {
 *     code: 'error code',
 *     message: 'error message'
 *   }
 * }
 *
 *****************************************************************************************************************/

const MAX_RECONNECT_COUNT = 10;
var timeoutRef;

const socketModule = {
  state: {
    connected: false,
    reconnecting: false,
    reconnectCount: 0,
    touchPanelBrand: "CR"
  },

  getters: {
    isConnected(state) {
      return state.connected;
    },

    isReconnecting(state) {
      return state.reconnecting;
    },

    touchPanelBrand: state => state.touchPanelBrand
  },

  mutations: {
    [MUTATION_SOCKET_ONOPEN](state, event) {
      console.log("Socket opened", state);

      state.connected = true;
      state.reconnecting = false;
    },

    [MUTATION_SOCKET_ONCLOSE](state, event, data) {
      console.log("Socket closed", state, data);

      state.connected = false;
      if (state.reconnecting && state.reconnectCount >= MAX_RECONNECT_COUNT) {
        state.reconnecting = false;
      }
    },

    [MUTATION_SOCKET_ONERROR](state, event, data) {
      console.error("An error occured in the socket", state, data);
    },

    [MUTATION_SOCKET_ONMESSAGE](state, message) {
      console.log("A message came from the socket", message, state);

      if (message.brand) {
        state.touchPanelBrand = message.brand;
      }

      const favicon = document.getElementById("favicon");

      if (message.brand === "EAE") {
        favicon.href = "eae.png";
        document.title = "Miola";
      } else {
        favicon.href = "core.png";
        document.title = "CoreOS";
      }
    },

    [MUTATION_SOCKET_RECONNECT](state, count) {
      console.log("Socket is reconnecting", count, state);

      state.connected = false;
      state.reconnecting = true;
    },

    [MUTATION_SOCKET_RECONNECT_ERROR](state) {
      console.error("Error in reconnection", state);

      state.connected = false;
      state.reconnecting = true;
    }
  },

  actions: {
    executeAction({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        console.log(payload);
        rootState.promises[payload.method] = { resolve, reject };

        Vue.prototype.$socket.sendObj({
          method: payload.method,
          data: {
            token: rootState.authModule.token,
            payload: payload.data,
          },
        });
      });
    },

    onReceivedEvent({ rootState, commit }, payload) {
      console.log("GELMMMEEEE", payload);
      let event = payload.event;
      console.log(event);

      if (timeoutRef) clearTimeout(timeoutRef);

      if (event.error) {
        if (event.error.code == ERROR_UNAUTHORIZED) {
          commit(MUTATION_SET_TOKEN, "");
        }

        rootState.promises[event.method].reject(event.error.description);
      } else {
        timeoutRef = setTimeout(() => {
          commit(MUTATION_SET_TOKEN, "");
        }, 15 * 60 * 1000);

        const data = event.response.data;
        commit(payload.mutation, data);
        rootState.promises[event.method].resolve(event.response);
      }
    },
  },
};

const APIModule = {
  state: {
    connected: true,
    reconnecting: true,
    reconnectCount: 0,
    touchPanelBrand: "CR"
  },

  getters: {
    isConnected(state) {
      return state.connected;
    },

    isReconnecting(state) {
      return state.reconnecting;
    },

    touchPanelBrand: state => state.touchPanelBrand
  },

  mutations: {
  },

  actions: {
  },
};

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? socketModule : APIModule;
