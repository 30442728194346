import {METHOD_ENABLE_ADB, METHOD_ENABLE_OTG, METHOD_PLAY_SOUND, METHOD_RUN_SIP_ACTION, METHOD_REBOOT_DEVICE} from "../method-names";
import {playAudio, rebootDevice, runSipAction, enableADB, enableOTG} from "@/api/command";
import {MUTATION_ENABLE_ADB, MUTATION_ENABLE_OTG, MUTATION_PLAY_SOUND, MUTATION_RUN_SIP_ACTION, MUTATION_REBOOT_DEVICE} from "../mutation-types";


const commandModule = {
  state: {
    commands: {
      adbEnable: false
    }
  },

  getters: {

  },

  mutations: {
    [MUTATION_ENABLE_ADB] (state, data){

    },

    [MUTATION_PLAY_SOUND] (state, data){

    },

    [MUTATION_RUN_SIP_ACTION] (state, data){

    },

    [MUTATION_REBOOT_DEVICE] (state, data){

    },
  },

  actions: {
    /**
     * request method for enable or disable ADB
     * */
    enableADB ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ENABLE_ADB, data: payload })
    },

    /**
     * request method for enable or disable OTG
     * */
    enableOTG ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ENABLE_OTG, data: payload })
    },


    /**
     * request method for play sound
     * */
    playSound ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_PLAY_SOUND, data: payload })
    },

    /**
     * request method for execute sip action
     * */
    executeSipAction ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_RUN_SIP_ACTION, data: payload })
    },

    /**
     * request method for reboot device
     * */
    rebootDevice ({dispatch}) {
      return dispatch('executeAction', { method: METHOD_REBOOT_DEVICE, data: "Reboot Device" })
    },



    /**
     * response method for {@link enableADB}
     *
     * @param event data from socket
     * */
    onEnableADB ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ENABLE_ADB})
    },

    /**
     * response method for {@link enableOTG}
     *
     * @param event data from socket
     * */
    onEnableOTG ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ENABLE_OTG})
    },

    /**
     * response method for {@link playSound}
     *
     * @param event data from socket
     * */
    onPlaySound ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_PLAY_SOUND})
    },

    /**
     * response method for {@link executeSipAction}
     *
     * @param event data from socket
     * */
    onExecuteSipAction ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_RUN_SIP_ACTION})
    },

    /**
     * response method for {@link rebootDevice}
     *
     * @param event data from socket
     * */
    onRebootDevice ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REBOOT_DEVICE})
    },
  }
}

const commandAPI = {
  state: {
    commands: {
      adbEnable: false
    }
  },

  getters: {

  },

  mutations: {
    [MUTATION_ENABLE_ADB] (state, data){

    },

    [MUTATION_PLAY_SOUND] (state, data){

    },

    [MUTATION_RUN_SIP_ACTION] (state, data){

    },

    [MUTATION_REBOOT_DEVICE] (state, data){

    },
  },

  actions: {
    /**
     * request method for enable or disable ADB
     * */
    enableADB ({dispatch}, payload) {
      return dispatch('runAction', { action: enableADB, data: payload, mutation: MUTATION_ENABLE_ADB })
    },

    /**
     * request method for enable or disable OTG
     * */
    enableOTG ({dispatch}, payload) {
      return dispatch('runAction', { action: enableOTG, data: payload, mutation: MUTATION_ENABLE_OTG })
    },


    /**
     * request method for play sound
     * */
     playAudio ({dispatch}, payload) {
      return dispatch('runAction', { action: playAudio, data: payload, mutation: MUTATION_PLAY_SOUND })
    },

    /**
     * request method for execute sip action
     * */
    executeSipAction ({dispatch}, payload) {
      return dispatch('runAction', { action: runSipAction, data: payload, mutation: MUTATION_RUN_SIP_ACTION })
    },

    /**
     * request method for reboot device
     * */
    rebootDevice ({dispatch}) {
      return dispatch('runAction', { action: rebootDevice, mutation: MUTATION_REBOOT_DEVICE })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? commandModule : commandAPI;