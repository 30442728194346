<template>
  <el-dialog :title="$t('topbar.password-change')" :visible.sync="visible" @closed="$emit('onCancelDialog')">

    <el-form ref="form" :model="data">

      <el-form-item :label="$t('topbar.new-password')" label-width="180px" prop="password" :rules="{required: true}">
        <el-input ref="pwd" v-model="data.password" type="password" autocomplete="off" @keyup.enter.native="saveRoom"></el-input>
      </el-form-item>

      <el-form-item :label="$t('topbar.new-password-again')" label-width="180px" prop="passwordAgain" :rules="{required: true}">
        <el-input ref="pwdAgain" v-model="data.passwordAgain" type="password" autocomplete="off" @keyup.enter.native="saveRoom"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
    <el-button @click="$emit('onCancelDialog')">{{ $t('topbar.cancel') }}</el-button>
    <el-button type="primary" @click="changePassword">{{ $t('topbar.confirm') }}</el-button>
  </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "PasswordChangeDialog",

    props: {
      visible: {
        type: Boolean,
        required: true,
        default: false
      }
    },

    data: function(){
      return {
        data: {
          password: "",
          passwordAgain: ""
        }
      }
    },

    methods: {

      changePassword(){

        this.$refs.form.validate(valid => {
          if (valid) {

            if (this.data.password !== this.data.passwordAgain) {
              this.$message({ message: this.$t('topbar.pwd-fields-not-equal'), type: 'error' })
              return
            }

            this.$store.dispatch("changePassword", this.data.password).then(response => {

              this.$message({ message: this.$t(response.message), type: 'success' })
              this.$emit('onChangePassword', true)

            }).catch(reason => {
              this.$message({ message: this.$t(reason), type: 'error' })
              this.$emit('onChangePassword', false)
            })

          }
        })
      }
    },
  }
</script>
