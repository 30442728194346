<template>
  <div :class="{'bg-eae':brand == 'EAE', bg:brand != 'EAE'}">

    <div class="lang-menu">
      <el-dropdown trigger="click" @command="selectLanguage">
        <span class="el-dropdown-link" style="color: #eeeeee"><img src="@/assets/blank.gif" :class="currentLang.icon"> {{ $t(currentLang.title) }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="lang" v-for="lang in langs" :key="lang.code"><img src="@/assets/blank.gif" :class="lang.icon"> {{ $t(lang.title) }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="login-container">

      <el-form :model="userData" :rules="rules" ref="userData" label-position="center" style="width: 300px">
        <h3 class="title">{{ $t('auth.login') }}</h3>

        <el-form-item prop="username">
          <el-input prefix-icon="fa fa-user-circle" type="text" v-model="userData.username" auto-complete="on" :placeholder="$t('auth.enter-uname')" @keyup.enter.native="submitLoginForm"></el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input prefix-icon="fa fa-key" type="password" v-model="userData.password" auto-complete="off" :placeholder="$t('auth.enter-pwd')" @keyup.enter.native="submitLoginForm"></el-input>
        </el-form-item>

        <el-form-item style="text-align: left">
          <el-button type="primary" @click.native.prevent="submitLoginForm" :loading="logining">{{ $t('auth.sign-in') }}</el-button>

        </el-form-item>

      </el-form>



    </div>


  </div>


</template>

<script>
  export default {
    name: "Login",
    data: function () {
      return {
        langs: [
          {code: "tr", title: "topbar.turkish", icon: "flag flag-tr"},
          {code: "en", title: "topbar.english", icon: "flag flag-us"}
        ],

        logining: false,
        userData: {
          username: "",
          password: ""
        },
        rules: {
          username: [
            { required: true, message: this.$t('auth.form-rules.enter-uname'), trigger: 'blur' }
          ],
          password: [
            { required: true, message: this.$t('auth.form-rules.enter-pwd'), trigger: 'blur' }
          ]
        }
      }
    },

    computed: {
      isSignedIn: function () {
        return this.$store.getters.isSignedIn;
      },

      currentLang(){
        return this.langs.find(lang => lang.code == this.$i18n.locale)
      },

      brand: function () {
        return this.$store.getters.touchPanelBrand;
      }
    },

    watch: {
      "$i18n.locale": function (value) {
        localStorage.setItem("lang", value);
      }
    },

    methods: {
      submitLoginForm: function (event) {
        const self = this

        this.$refs.userData.validate((valid) => {
          if (valid) {
            this.logining = true;

            this.$store.dispatch('signIn', this.userData).then(response => {
              console.log(response)
              this.logining = false;

              this.$message({ message: this.$t(response), type: 'success' })

              this.$router.push({name: 'Dashboard'})

            }).catch(error => {
              console.log(error)
              this.logining = false;
              this.$message({ message: this.$t(error), type: 'error' })
            })
          }
        })
      },

      selectLanguage(lang) {
        this.currentLang = lang
        this.$i18n.locale = lang.code
      },
    },

    
  }
</script>


<style scoped>
  .bg {
    background-image: url("/assets/bg.jpeg");
    /*background-color: #cccccc;*/
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-eae {
    background-image: url("/assets/bg_eae.jpeg");
    /*background-color: #cccccc;*/
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }


  .login-container {
    /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    /*margin: 80px auto;*/
    margin: 20px;
    width: 500px;
    padding: 35px 35px 15px 35px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
  }

  .lang-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .lang-menu:active {
    background-color: rgba(255, 255, 255, 0.0);
  }
</style>