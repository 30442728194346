import {
  MUTATION_SET_WIDGETS,
  MUTATION_EDIT_WIDGETS,
} from "../mutation-types";
import {
  METHOD_GET_WIDGETS,
  METHOD_EDIT_WIDGETS,
} from "../method-names";
import { 
  getWidgets, 
  editWidgets 
} from "@/api/widget";

const widgetModule = {
  state: {
    widgets: []
  },

  getters: {
    widgets: state => state.widgets,
    widgetCount: state => state.widgets.length
  },

  mutations: {
    [MUTATION_SET_WIDGETS] (state, widgets) {
      state.widgets = widgets
    },

    [MUTATION_EDIT_WIDGETS] (state, widgets) {
      state.widgets = widgets
    },
  },

  actions: {
    /**
     * request method for all widgets
     * */
    getWidgets({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_WIDGETS })
    },

    /**
     * request method for edit widgets
     *
     * @param payload room data
     * */
    editWidgets({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_EDIT_WIDGETS, data: payload })
    },



    /**
     * response method for {@link getWidgets}
     *
     * @param event data from socket
     * */
    onReceivedWidgets ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_WIDGETS })
    },

    /**
     * response method for {@link editWidgets}
     *
     * @param event data from socket
     * */
    onEditWidgets ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_WIDGETS })
    },
  }
}

const widgetAPI = {
  state: {
    widgets: []
  },

  getters: {
    widgets: state => state.widgets,
    widgetCount: state => state.widgets.length
  },

  mutations: {
    [MUTATION_SET_WIDGETS] (state, widgets) {
      state.widgets = widgets
    },

    [MUTATION_EDIT_WIDGETS] (state, widgets) {
      state.widgets = widgets
    },
  },

  actions: {
    /**
     * request method for all widgets
     * */
    getWidgets({dispatch}) {
      return dispatch('runAction', { action: getWidgets, mutation: MUTATION_SET_WIDGETS })
    },

    /**
     * request method for edit widgets
     *
     * @param payload room data
     * */
    editWidgets({dispatch}, payload) {
      return dispatch('runAction', { action: editWidgets, data: payload, mutation: MUTATION_EDIT_WIDGETS })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? widgetModule : widgetAPI;