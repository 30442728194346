import {
  METHOD_ADD_IPCAMERA,
  METHOD_EDIT_IPCAMERA,
  METHOD_GET_IPCAMERAS,
  METHOD_REMOVE_IPCAMERA, METHOD_SYNC_IPCAMERAS
} from "../method-names";

import { 
  getIPCameras, 
  syncIPCameras, 
  addIPCamera, 
  editIPCamera, 
  removeIPCamera 
} from "@/api/ip-camera";

import {
  MUTATION_ADD_IPCAMERA,
  MUTATION_EDIT_IPCAMERA,
  MUTATION_REMOVE_IPCAMERA,
  MUTATION_SET_IPCAMERAS,
} from "../mutation-types";


const ipCameraModule = {
  state: {
    ipcameras: []
  },

  getters: {
    ipcameras: state => state.ipcameras,
    ipcameraCount: state => state.ipcameras.length
  },

  mutations: {
    [MUTATION_SET_IPCAMERAS] (state, ipcameras) {
      state.ipcameras = ipcameras
    },

    [MUTATION_ADD_IPCAMERA] (state, camera) {
      state.ipcameras.push(camera)
    },

    [MUTATION_EDIT_IPCAMERA] (state, newCamera) {
      state.ipcameras = state.ipcameras.map(camera => {
        return camera.id == newCamera.id ? newCamera : camera
      })
    },

    [MUTATION_REMOVE_IPCAMERA] (state, cameraId) {
      state.ipcameras = state.ipcameras.filter(camera => camera.id != cameraId)
    }
  },

  actions: {

    /**
     * request method for all IP Cameras
     * */
    getIPCameras({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_IPCAMERAS })
    },

    /**
     * request method for sync IP Cameras
     *
     * @param payload ipcamera data
     * */
    syncIPCameras({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SYNC_IPCAMERAS, data: payload })
    },

    /**
     * request method for adding IP Camera
     *
     * @param payload ipcamera data
     * */
    addIPCamera({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ADD_IPCAMERA, data: payload })
    },

    /**
     * request method for editting IP Camera
     *
     * @param payload ipcamera data
     * */
    editIPCamera({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_EDIT_IPCAMERA, data: payload })
    },

    /**
     * request method for removing IP Camera
     *
     * @param payload ipcamera data
     * */
    removeIPCamera({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_REMOVE_IPCAMERA, data: payload })
    },


    /**
     * response method for {@link getIPCameras}
     *
     * @param event data from socket
     * */
    onReceivedIPCameras({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_IPCAMERAS })
    },

    /**
     * response method for {@link syncIPCameras}
     *
     * @param event data from socket
     * */
    onSyncIPCameras({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_IPCAMERAS })
    },

    /**
     * response method for {@link addIPCamera}
     *
     * @param event data from socket
     * */
    onAddIPCamera({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ADD_IPCAMERA })
    },

    /**
     * response method for {@link editIPCamera}
     *
     * @param event data from socket
     * */
    onEditIPCamera({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_IPCAMERA })
    },

    /**
     * response method for {@link removeIPCamera}
     *S
     * @param event data from socket
     * */
    onRemoveIPCamera({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REMOVE_IPCAMERA })
    }
  },
}

const ipCameraAPI = {
  state: {
    ipcameras: []
  },

  getters: {
    ipcameras: state => state.ipcameras,
    ipcameraCount: state => state.ipcameras.length
  },

  mutations: {
    [MUTATION_SET_IPCAMERAS] (state, ipcameras) {
      state.ipcameras = ipcameras
    },

    [MUTATION_ADD_IPCAMERA] (state, camera) {
      state.ipcameras.push(camera)
    },

    [MUTATION_EDIT_IPCAMERA] (state, newCamera) {
      state.ipcameras = state.ipcameras.map(camera => {
        return camera.id == newCamera.id ? newCamera : camera
      })
    },

    [MUTATION_REMOVE_IPCAMERA] (state, cameraId) {
      state.ipcameras = state.ipcameras.filter(camera => camera.id != cameraId)
    }
  },

  actions: {

    /**
     * request method for all IP Cameras
     * */
    getIPCameras({dispatch}) {
      return dispatch('runAction', { action: getIPCameras, mutation: MUTATION_SET_IPCAMERAS })
    },

    /**
     * request method for sync IP Cameras
     *
     * @param payload ipcamera data
     * */
    syncIPCameras({dispatch}, payload) {
      return dispatch('runAction', { action: syncIPCameras, data: payload, mutation: MUTATION_SET_IPCAMERAS })
    },

    /**
     * request method for adding IP Camera
     *
     * @param payload ipcamera data
     * */
    addIPCamera({dispatch}, payload) {
      return dispatch('runAction', { action: addIPCamera, data: payload, mutation: MUTATION_ADD_IPCAMERA })
    },

    /**
     * request method for editting IP Camera
     *
     * @param payload ipcamera data
     * */
    editIPCamera({dispatch}, payload) {
      return dispatch('runAction', { action: editIPCamera, data: payload, mutation: MUTATION_EDIT_IPCAMERA })
    },

    /**
     * request method for removing IP Camera
     *
     * @param payload ipcamera data
     * */
    removeIPCamera({dispatch}, payload) {
      return dispatch('runAction', { action: removeIPCamera, data: payload, mutation: MUTATION_REMOVE_IPCAMERA })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? ipCameraModule : ipCameraAPI;