import {MUTATION_SET_LOGS, MUTATION_SET_MESSAGES} from "../mutation-types";
import {METHOD_GET_LOGS, METHOD_GET_RAW_LOGS} from "../method-names";
import { getLogs } from "@/api/log";

const logModule = {
  state: {
    messages: [],
    logs: [
      {
        tag: "Out of Memory",
        message: "WifiStateMachine: WifiStateMachine CMD_START_SCAN with age=220069 interval=341718 maxinterval=300000 WifiStateMachine CMD_START_SCAN full=false",
        level: "I",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
      {
        tag: "No value for Event",
        message: "onMessage Error : org.json.JSONException: No value for Event at org.json.JSONObject.get(JSONObject.java:389) at org.json.JSONObject.getString(JSONObject.java:550) at com.core.homehubkit.HHHomeHubController.onMessage(HHHomeHubController.java:1106) at com.core.homehubkit.server.HHHomeServer.onMessage(HHHomeServer.java:118)",
        level: "D",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
      {
        tag: "Out of Memory",
        message: "WifiStateMachine: WifiStateMachine CMD_START_SCAN with age=220069 interval=341718 maxinterval=300000 WifiStateMachine CMD_START_SCAN full=false",
        level: "E",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
      {
        tag: "Out of Memory",
        message: "WifiStateMachine: WifiStateMachine CMD_START_SCAN with age=220069 interval=341718 maxinterval=300000 WifiStateMachine CMD_START_SCAN full=false",
        level: "I",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
      {
        tag: "Out of Memory",
        message: "WifiStateMachine: WifiStateMachine CMD_START_SCAN with age=220069 interval=341718 maxinterval=300000 WifiStateMachine CMD_START_SCAN full=false",
        level: "W",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
    ]
  },

  getters: {
    logs: state => state.logs,
    messages: state => state.messages
  },

  mutations: {
    [MUTATION_SET_LOGS] (state, logs) {
      state.logs = logs
    },
    [MUTATION_SET_MESSAGES] (state, messages) {
      state.messages = messages
    },
  },

  actions: {
    getLogMessages ({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_LOGS})
    },



    onReceivedLogMessages ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_LOGS})
    },
  }

}

const logAPI = {
  state: {
    messages: [],
    logs: [
      {
        tag: "Out of Memory",
        message: "WifiStateMachine: WifiStateMachine CMD_START_SCAN with age=220069 interval=341718 maxinterval=300000 WifiStateMachine CMD_START_SCAN full=false",
        level: "I",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
      {
        tag: "No value for Event",
        message: "onMessage Error : org.json.JSONException: No value for Event at org.json.JSONObject.get(JSONObject.java:389) at org.json.JSONObject.getString(JSONObject.java:550) at com.core.homehubkit.HHHomeHubController.onMessage(HHHomeHubController.java:1106) at com.core.homehubkit.server.HHHomeServer.onMessage(HHHomeServer.java:118)",
        level: "D",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
      {
        tag: "Out of Memory",
        message: "WifiStateMachine: WifiStateMachine CMD_START_SCAN with age=220069 interval=341718 maxinterval=300000 WifiStateMachine CMD_START_SCAN full=false",
        level: "E",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
      {
        tag: "Out of Memory",
        message: "WifiStateMachine: WifiStateMachine CMD_START_SCAN with age=220069 interval=341718 maxinterval=300000 WifiStateMachine CMD_START_SCAN full=false",
        level: "I",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
      {
        tag: "Out of Memory",
        message: "WifiStateMachine: WifiStateMachine CMD_START_SCAN with age=220069 interval=341718 maxinterval=300000 WifiStateMachine CMD_START_SCAN full=false",
        level: "W",
        createdAt: "16.12.2018 12:34",
        processId: 1
      },
    ]
  },

  getters: {
    logs: state => state.logs,
    messages: state => state.messages
  },

  mutations: {
    [MUTATION_SET_LOGS] (state, logs) {
      state.logs = logs
    },
    [MUTATION_SET_MESSAGES] (state, messages) {
      state.messages = messages
    },
  },

  actions: {
    getLogMessages ({dispatch}) {
      return dispatch('runAction', { action: getLogs, mutation: MUTATION_SET_LOGS })
    }
  }

}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? logModule : logAPI;