import request from '@/api/relay-service'
import { METHOD_GET_GENERAL_INFO, METHOD_GET_GENERAL_SETTINGS, METHOD_SAVE_GENERAL_SETTINGS } from '../store/method-names'

export function getGeneralInfo(){
    return request({
        params: {
            action: METHOD_GET_GENERAL_INFO
        },
        method: 'get'
    })
}

export function getGeneralSettings(){
    return request({
        params: {
            action: METHOD_GET_GENERAL_SETTINGS
        },
        method: 'get'
    })
}

export function editGeneralSettings(data){
    return request({
        params: {
            action: METHOD_SAVE_GENERAL_SETTINGS
        },
        method: 'post',
        data
    })
}