import request from '@/api/relay-service'
import { METHOD_EDIT_RSS_SETTINGS, METHOD_GET_RSS_SETTINGS } from '../store/method-names'

export function getRssSettings(){
    return request({
        params: {
            action: METHOD_GET_RSS_SETTINGS
        },
        method: 'get'
    })
}

export function editRssSettings(data){
    return request({
        params: {
            action: METHOD_EDIT_RSS_SETTINGS
        },
        method: 'post',
        data
    })
}