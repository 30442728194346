import request from '@/api/relay-service'
import { METHOD_GET_ACCESSORIES, METHOD_SYNC_ACCESSORIES, METHOD_ADD_ACCESSORY, METHOD_EDIT_ACCESSORY, METHOD_REMOVE_ACCESSORY } from '../store/method-names'

const accessoriesFilterKey = "core-dp-accessories-filters"

export function getAccessories(){
    return request({
        params: {
            action: METHOD_GET_ACCESSORIES
        },
        method: 'get'
    })
}

export function syncAccessories(data){
    return request({
        params: {
            action: METHOD_SYNC_ACCESSORIES
        },
        method: 'post',
        data
    })
}

export function addAccessory(data){
    return request({
        params: {
            action: METHOD_ADD_ACCESSORY
        },
        method: 'post',
        data
    })
}

export function editAccessory(data){
    return request({
        params: {
            action: METHOD_EDIT_ACCESSORY
        },
        method: 'post',
        data
    })
}

export function removeAccessory(id){
    return request({
        params: {
            action: METHOD_REMOVE_ACCESSORY,
            id: id
        },
        method: 'delete'
    })
}

export function getFiltersFromLocalStorage() {
    const filters = localStorage.getItem(accessoriesFilterKey)
    if (filters) {
        return JSON.parse(filters)
    }
    return null
}

export function setFiltersToLocalStorage(filters) {
    localStorage.setItem(accessoriesFilterKey, JSON.stringify(filters))
}

export function removeFiltersFromLocalStorage() {
    localStorage.removeItem(accessoriesFilterKey)
}