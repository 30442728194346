import Vue from "vue";
import Vuex from "vuex";
import router from "../router/router";
import VueNativeSock from "vue-native-websocket";
import socketModule from "./modules/socket";
import authModule from "./modules/auth";
import driverModule from "./modules/drivers"; 
import roomModule from "./modules/room"; 
import sectionModule from "./modules/section"; 
import accessoryModule from "./modules/accessory"; 
import logModule from "./modules/logs"; 
import intercomModule from "./modules/intercom"; 
import automationModule from "./modules/automation"; 
import sceneModule from "./modules/scene"; 
import widgetModule from "./modules/widget"; 
import menuItemsModule from "./modules/menu-items"; 
import generalModule from "./modules/general"; 
import commandModule from "./modules/command"; 
import networkModule from "./modules/network"; 
import securityModule from "./modules/security"; 
import ipCameraModule from "./modules/ipcamera"; 
import panelSettingsModule from "./modules/panel-settings"; 
import rssModule from "./modules/rss";
import { MUTATION_SET_TOKEN } from "./mutation-types";
import { LOCAL_MODE, LOCAL_IP } from "./constants";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    promises: {},
    menuCollapsed: false,
  },

  getters: {
    isMenuCollapsed: (state) => {
      return state.menuCollapsed;
    },
  },

  mutations: {
    toggleMenu: (state) => {
      state.menuCollapsed = !state.menuCollapsed;
    },
  },

  actions: {
    runAction({commit}, payload) {
      // console.log('PAYLOAD', payload.mutation, 'DATA', payload.data)
      return new Promise((resolve, reject) => {
        payload.action(payload.data)
        .then(response => {
          commit(payload.mutation, response.data)
          resolve(response)
          console.log('PAYLOAD', payload.mutation, 'DATA', payload.data, "RESPONSE", response.data)
        })
        .catch(error => {
          reject(error)
        })
      })
    }
  },

  modules: {
    socketModule,
    authModule,
    driverModule,
    accessoryModule,
    ipCameraModule,
    roomModule,
    sectionModule,
    logModule,
    intercomModule,
    sceneModule,
    automationModule,
    widgetModule,
    menuItemsModule,
    generalModule,
    commandModule,
    networkModule,
    securityModule,
    panelSettingsModule,
    rssModule,
  },
});

if(process.env.VUE_APP_REMOTE_MODE == 'false'){
  Vue.use(
    VueNativeSock,
    LOCAL_MODE ? LOCAL_IP : "ws://" + location.hostname + ":8887",
    {
      //'ws://192.168.206.73:8887'    "ws://" + location.hostname + ":8887"
      store,
      format: "json",
      reconnection: true,
      reconnectionAttempts: 100,
      reconnectionDelay: 3000,
    }
  );
}


store.subscribe((mutation, state) => {
  if (mutation.type == MUTATION_SET_TOKEN) {
    if (!state.authModule.token) {
      if (router.currentRoute.path != "/login") {
        router.push({ name: "Login" });
      }
    } else {
      setTimeout(() => {
        if (router.currentRoute.path == "/login") {
          router.push({ name: "Dashboard" });
        }
      }, 500);
    }
  }
});

export default store;
