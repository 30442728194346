import axios from "axios"
import { getUserId, getToken, getDeviceId, getTechnicalAccessCode, getExpireDate, setExpireDate } from "@/api/auth"
import { Message, MessageBox } from "element-ui"
import { EXPIRE_TIME } from "../store/constants"
import store from "../store/store"
import router from "../router/router"
import i18n from '../i18n'

const service = axios.create({
    baseURL: `${process.env.VUE_APP_RELAY_SERVER_URL}/api/v1/relay/`,
    crossDomain: true,
    timeout: 5000,
    //headers: {'Content-Type': 'application/json'}
})


service.interceptors.request.use(config => {
    const token = getToken()
    const userId = getUserId()
    const deviceId = getDeviceId()
    const accessCode = getTechnicalAccessCode()
    const expireDate = getExpireDate()

    config.params.method = "requestDPConfigurations"

    //config.headers['Content-Type'] = "text/plain"
    
    config.headers['X-User-Type'] = "integrator"

    if (token && userId) {
        config.headers['X-User-Id'] = userId
        config.headers['X-Auth-Token'] = token
    }

    if(expireDate < Date.now()){ 
        const message = "Yetkisiz Giriş" 
        Message({
            message: message,
            type: "error",
            duration: 5 * 1000
        })
        store.dispatch('logout');
        return Promise.reject(new Error(message))
    }
    else {
        setExpireDate(Date.now() + EXPIRE_TIME)
    }

    if (!deviceId || !accessCode) {
        const message = "Yetkisiz Giriş" 
        Message({
            message: message,
            type: "error",
            duration: 5 * 1000
        })
        store.dispatch('logout');
        return Promise.reject(new Error(message))
    }

    config.params.token = getTechnicalAccessCode()
    config.baseURL = config.baseURL + deviceId

    console.log("request config", config)
    return config
}, error => {
    console.log("Request Error", error)
    return Promise.reject(error)
})


service.interceptors.response.use(response => {
    const responseData = response.data

    if (response.status != 200) {
        console.log("Response Error 1", responseData.message)
        return Promise.reject(new Error(responseData.message || "Response Error"))
    } else {
        if (responseData.code != 200) {
            if (responseData.code == 401) {
                store.dispatch('resetDeviceInfo');
                if (router.currentRoute.path != "/access-code") {
                    Message({
                        message: responseData.message,
                        type: "error",
                        duration: 5 * 1000
                    })
                    router.push({ name: "AccessCode" });
                }
            }
            return Promise.reject(new Error(responseData.message || "Response Error"))
        } else {
            const data = JSON.parse(responseData.data)
            console.log("RELAY SERVER RESPONSE - METHOD CALLING ->", data.method, " RESPONSE ->", data.response.data)
            if (data.error) {
                if (data.error.code == 401) {
                    store.dispatch('resetDeviceInfo');
                    if (router.currentRoute.path != "/access-code") {
                        Message({
                            message: i18n.t(data.error.description),
                            type: "error",
                            duration: 5 * 1000
                        })
                        router.push({ name: "AccessCode" });
                    }
                } 
                return Promise.reject(new Error(data.error.description || "Response Error"))
            }
            return data.response
        }
    }
}, error => {
    console.log("Response Error 2", error)
    return Promise.reject(error)
})

export default service