export const DEFAULT_ROOM_ID = 1;

export const ACCESSORY_CATEGORY_SWITCH = 1;
export const ACCESSORY_CATEGORY_DIMMER = 2;
export const ACCESSORY_CATEGORY_THERMOSTAT = 3;
export const ACCESSORY_CATEGORY_SHUTTER = 4;
export const ACCESSORY_CATEGORY_IPCAMERA = 5;
export const ACCESSORY_CATEGORY_DOORBELL = 6;
export const ACCESSORY_CATEGORY_TUNABLE_WHITE = 7;

export const ACCESSORY_CATEGORY_AIR_CONDITIONER = 8;

export const ACCESSORY_CATEGORY_SENSOR_MOTION = 9;
export const ACCESSORY_CATEGORY_SENSOR_DOOR = 10;
export const ACCESSORY_CATEGORY_SENSOR_WINDOW = 11;
export const ACCESSORY_CATEGORY_SENSOR_LEAK = 12;
export const ACCESSORY_CATEGORY_SENSOR_GAS = 13;
export const ACCESSORY_CATEGORY_SENSOR_SMOKE = 14;

export const ACCESSORY_CATEGORY_CONTACTOR = 21;
export const ACCESSORY_CATEGORY_WATER_VALVE = 22;
export const ACCESSORY_CATEGORY_GAS_VALVE = 23;
export const ACCESSORY_CATEGORY_ELEVATOR = 24;
export const ACCESSORY_CATEGORY_ELECTRICAL_DEVICE = 25;
export const ACCESSORY_CATEGORY_SIREN = 26;
export const ACCESSORY_CATEGORY_PUNCH_BUTTON = 27;
export const ACCESSORY_CATEGORY_BINARY_BUTTON = 28;

export const ACCESSORY_CATEGORY_DOORLOCK = 50;

export const ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT = 51;
export const ACCESSORY_CATEGORY_STATUS_DISPLAY = 52;

export const ACCESSORY_CATEGORY_RGBW = 53;
export const ACCESSORY_CATEGORY_AUDIO = 54;

export const ACCESSORY_GROUP_LIGHTING = 1;
export const ACCESSORY_GROUP_SENSOR = 2;
export const ACCESSORY_GROUP_CAMERA = 3;
export const ACCESSORY_GROUP_HVAC = 4;
export const ACCESSORY_GROUP_SHUTTER = 5;
export const ACCESSORY_GROUP_ONOFF = 6;
export const ACCESSORY_GROUP_DOORLOCK = 7;
export const ACCESSORY_GROUP_DOORBELL = 8;
export const ACCESSORY_GROUP_STATUS_DISPLAY = 9;
export const ACCESSORY_GROUP_MULTIMEDIA = 10;

export const DISPLAY_CATEGORY_DEFAULT = 0;
export const DISPLAY_CATEGORY_LIGHTING = 1;
export const DISPLAY_CATEGORY_ONOFF = 2;
export const DISPLAY_CATEGORY_SHUTTER = 3;
export const DISPLAY_CATEGORY_HVAC = 4;
export const DISPLAY_CATEGORY_SENSOR = 5;
export const DISPLAY_CATEGORY_CAMERA = 6;
export const DISPLAY_CATEGORY_DOORLOCK = 7;
export const DISPLAY_CATEGORY_MULTIMEDIA = 8;

export const displayCategories = [
  { title: "accessory.lighting", value: DISPLAY_CATEGORY_LIGHTING },
  { title: "accessory.on-off-control", value: DISPLAY_CATEGORY_ONOFF },
  { title: "accessory.shutter", value: DISPLAY_CATEGORY_SHUTTER },
  { title: "accessory.thermostat", value: DISPLAY_CATEGORY_HVAC },
  { title: "accessory.multimedia", value: DISPLAY_CATEGORY_MULTIMEDIA },
];

export function displayCategory(category) {
  if ([ACCESSORY_CATEGORY_DIMMER, ACCESSORY_CATEGORY_RGBW, ACCESSORY_CATEGORY_TUNABLE_WHITE].includes(category))
    return DISPLAY_CATEGORY_LIGHTING;
  else if ([ACCESSORY_CATEGORY_AUDIO].includes(category))
    return DISPLAY_CATEGORY_MULTIMEDIA;
  else if (
    [
      ACCESSORY_CATEGORY_CONTACTOR,
      ACCESSORY_CATEGORY_SIREN,
      ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
      ACCESSORY_CATEGORY_STATUS_DISPLAY,
      ACCESSORY_CATEGORY_WATER_VALVE,
      ACCESSORY_CATEGORY_GAS_VALVE,
      ACCESSORY_CATEGORY_ELEVATOR,
      ACCESSORY_CATEGORY_PUNCH_BUTTON,
      ACCESSORY_CATEGORY_BINARY_BUTTON,
    ].includes(category)
  )
    return DISPLAY_CATEGORY_ONOFF;
  else if (
    [
      ACCESSORY_CATEGORY_SENSOR_SMOKE,
      ACCESSORY_CATEGORY_SENSOR_GAS,
      ACCESSORY_CATEGORY_SENSOR_LEAK,
      ACCESSORY_CATEGORY_SENSOR_WINDOW,
      ACCESSORY_CATEGORY_SENSOR_DOOR,
      ACCESSORY_CATEGORY_SENSOR_MOTION,
    ].includes(category)
  )
    return DISPLAY_CATEGORY_SENSOR;
  else if (
    [
      ACCESSORY_CATEGORY_THERMOSTAT,
      ACCESSORY_CATEGORY_AIR_CONDITIONER,
      ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT,
    ].includes(category)
  )
    return DISPLAY_CATEGORY_HVAC;
  else if (category == ACCESSORY_CATEGORY_IPCAMERA)
    return DISPLAY_CATEGORY_CAMERA;
  else if (category == ACCESSORY_CATEGORY_SHUTTER)
    return DISPLAY_CATEGORY_SHUTTER;
  else if (category == ACCESSORY_CATEGORY_DOORLOCK)
    return DISPLAY_CATEGORY_DOORLOCK;
  else if (category == ACCESSORY_CATEGORY_DOORBELL)
    return DISPLAY_CATEGORY_DEFAULT;
  else return -1;
}

export const categoryNames = [
  { title: "accessory.switch", value: ACCESSORY_CATEGORY_SWITCH },
  { title: "accessory.dimmer", value: ACCESSORY_CATEGORY_DIMMER },
  { title: "accessory.tunable-white", value: ACCESSORY_CATEGORY_TUNABLE_WHITE },
  { title: "accessory.rgbw", value: ACCESSORY_CATEGORY_RGBW },
  { title: "accessory.thermostat", value: ACCESSORY_CATEGORY_THERMOSTAT },
  {
    title: "accessory.advanced-thermostat",
    value: ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT,
  },
  { title: "accessory.shutter", value: ACCESSORY_CATEGORY_SHUTTER },
  { title: "accessory.ipcamera", value: ACCESSORY_CATEGORY_IPCAMERA },
  { title: "accessory.doorbell", value: ACCESSORY_CATEGORY_DOORBELL },
  {
    title: "accessory.air-conditioner",
    value: ACCESSORY_CATEGORY_AIR_CONDITIONER,
  },
  { title: "accessory.punch-button", value: ACCESSORY_CATEGORY_PUNCH_BUTTON },
  { title: "accessory.binary-button", value: ACCESSORY_CATEGORY_BINARY_BUTTON },
  { title: "accessory.audio", value: ACCESSORY_CATEGORY_AUDIO },
  { title: "accessory.sensor-motion", value: ACCESSORY_CATEGORY_SENSOR_MOTION },
  { title: "accessory.sensor-door", value: ACCESSORY_CATEGORY_SENSOR_DOOR },
  { title: "accessory.sensor-window", value: ACCESSORY_CATEGORY_SENSOR_WINDOW },
  { title: "accessory.sensor-leak", value: ACCESSORY_CATEGORY_SENSOR_LEAK },
  { title: "accessory.sensor-gas", value: ACCESSORY_CATEGORY_SENSOR_GAS },
  { title: "accessory.sensor-smoke", value: ACCESSORY_CATEGORY_SENSOR_SMOKE },
  { title: "accessory.contactor", value: ACCESSORY_CATEGORY_CONTACTOR },
  { title: "accessory.water-valve", value: ACCESSORY_CATEGORY_WATER_VALVE },
  { title: "accessory.gas-valve", value: ACCESSORY_CATEGORY_GAS_VALVE },
  { title: "accessory.elevator", value: ACCESSORY_CATEGORY_ELEVATOR },
  {
    title: "accessory.electrical-device",
    value: ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
  },
  { title: "accessory.siren", value: ACCESSORY_CATEGORY_SIREN },
  { title: "accessory.doorlock", value: ACCESSORY_CATEGORY_DOORLOCK },
  {
    title: "accessory.status-display",
    value: ACCESSORY_CATEGORY_STATUS_DISPLAY,
  },
];

export function categoryName(category) {
  const cat = categoryNames.find((cat) => cat.value == category);
  return cat ? cat.title : category;
}

export function group(category) {
  if (
    [
      ACCESSORY_CATEGORY_SWITCH,
      ACCESSORY_CATEGORY_DIMMER,
      ACCESSORY_CATEGORY_TUNABLE_WHITE,
      ACCESSORY_CATEGORY_RGBW,
    ].includes(category)
  )
    return ACCESSORY_GROUP_LIGHTING;
  else if ([ACCESSORY_CATEGORY_AUDIO].includes(category))
    return ACCESSORY_GROUP_MULTIMEDIA;
  else if (
    [
      ACCESSORY_CATEGORY_CONTACTOR,
      ACCESSORY_CATEGORY_SIREN,
      ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
      ACCESSORY_CATEGORY_WATER_VALVE,
      ACCESSORY_CATEGORY_GAS_VALVE,
      ACCESSORY_CATEGORY_ELEVATOR,
      ACCESSORY_CATEGORY_PUNCH_BUTTON,
      ACCESSORY_CATEGORY_BINARY_BUTTON,
    ].includes(category)
  )
    return ACCESSORY_GROUP_ONOFF;
  else if (
    [
      ACCESSORY_CATEGORY_SENSOR_SMOKE,
      ACCESSORY_CATEGORY_SENSOR_GAS,
      ACCESSORY_CATEGORY_SENSOR_LEAK,
      ACCESSORY_CATEGORY_SENSOR_WINDOW,
      ACCESSORY_CATEGORY_SENSOR_DOOR,
      ACCESSORY_CATEGORY_SENSOR_MOTION,
    ].includes(category)
  )
    return ACCESSORY_GROUP_SENSOR;
  else if (
    [
      ACCESSORY_CATEGORY_THERMOSTAT,
      ACCESSORY_CATEGORY_AIR_CONDITIONER,
      ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT,
    ].includes(category)
  )
    return ACCESSORY_GROUP_HVAC;
  else if (category == ACCESSORY_CATEGORY_IPCAMERA)
    return ACCESSORY_GROUP_CAMERA;
  else if (category == ACCESSORY_CATEGORY_SHUTTER)
    return ACCESSORY_GROUP_SHUTTER;
  else if (category == ACCESSORY_CATEGORY_DOORLOCK)
    return ACCESSORY_GROUP_DOORLOCK;
  else if (category == ACCESSORY_CATEGORY_STATUS_DISPLAY)
    return ACCESSORY_GROUP_STATUS_DISPLAY;
  else return ACCESSORY_GROUP_DOORBELL;
}

export function className(category) {
  // console.log("className", category);
  switch (category) {
    case ACCESSORY_CATEGORY_SWITCH:
    case ACCESSORY_CATEGORY_CONTACTOR:
    case ACCESSORY_CATEGORY_WATER_VALVE:
    case ACCESSORY_CATEGORY_GAS_VALVE:
    case ACCESSORY_CATEGORY_ELEVATOR:
    case ACCESSORY_CATEGORY_ELECTRICAL_DEVICE:
    case ACCESSORY_CATEGORY_SIREN:
    case ACCESSORY_CATEGORY_PUNCH_BUTTON:
    case ACCESSORY_CATEGORY_BINARY_BUTTON:
      return "HHAccessorySwitch";
    case ACCESSORY_CATEGORY_DIMMER:
      return "HHAccessoryDimmer";
    case ACCESSORY_CATEGORY_TUNABLE_WHITE:
        return "HHAccessoryTunableWhite";
    case ACCESSORY_CATEGORY_THERMOSTAT:
    case ACCESSORY_CATEGORY_AIR_CONDITIONER:
      return "HHAccesssoryThermostat";
    case ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT:
      return "HHAccessoryAdvThermostat";
    case ACCESSORY_CATEGORY_SHUTTER:
      return "HHAccessoryWindowCovering";
    case ACCESSORY_CATEGORY_DOORBELL:
      return "HHAccessoryDoorbell";
    case ACCESSORY_CATEGORY_SENSOR_MOTION:
    case ACCESSORY_CATEGORY_SENSOR_DOOR:
    case ACCESSORY_CATEGORY_SENSOR_WINDOW:
    case ACCESSORY_CATEGORY_SENSOR_LEAK:
    case ACCESSORY_CATEGORY_SENSOR_GAS:
    case ACCESSORY_CATEGORY_SENSOR_SMOKE:
      return "HHAccessorySensor";
    case ACCESSORY_CATEGORY_DOORLOCK:
      return "HHAccessoryDoorLock";
    case ACCESSORY_CATEGORY_IPCAMERA:
      return "HHAccessoryIPCamera";
    case ACCESSORY_CATEGORY_STATUS_DISPLAY:
      return "HHAccessoryStatusDisplay";
    case ACCESSORY_CATEGORY_RGBW:
      return "HHAccessoryRGBWDimmer";
    case ACCESSORY_CATEGORY_AUDIO:
      return "HHAccessoryAudio";
    default:
      return "";
  }
}

export function getActionServiceString(service) {
  if (service === "setOn") return "Switch";
  else if (service === "setDeviceLevel") return "Change Level";
  else if (service === "setThermoMode") return "Set Thermostat Mode";
  else if (service === "setTemperature") return "Set Temperature";
  else if (service === "setWindowCoveringAction") return "Set Shutter Action";
  return service;
}

export function getActionArgumentValueString(value) {
  if (value == "thermoModeCool") return "Cooling";
  else if (value == "thermoModeHeat") return "Heating";
  else if (value == "thermoModeOff") return "Off";
  else if (value == "actionOpen") return "Open";
  else if (value == "actionClose") return "Close";
  else if (value == "actionStop") return "Stop";
  return value;
}

export function getActions(accessory) {
  let actions = [];

  if (className(accessory.category) == "HHAccessorySwitch") {
    var service = "setOn";

    if (accessory.category == ACCESSORY_CATEGORY_PUNCH_BUTTON) {
      service = "punch";
    } else if (accessory.category == ACCESSORY_CATEGORY_BINARY_BUTTON) {
      service = accessory.powerOn ? "actionOn" : "actionOff";
    }

    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: service,
      argumentList: {
        newTargetValue: accessory.powerOn,
      },
    });
  } else if (className(accessory.category) == "HHAccessoryDimmer") {
    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: "setOn",
      argumentList: {
        newTargetValue: accessory.powerOn,
      },
    });

    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: "setDeviceLevel",
      argumentList: {
        newTargetValue: accessory.level,
      },
    });
  } else if (className(accessory.category) == "HHAccessoryDoorLock") {
    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: "setOn",
      argumentList: {
        newTargetValue: accessory.powerOn,
      },
    });
  } else if (className(accessory.category) == "HHAccesssoryThermostat") {
    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: "setThermoMode",
      argumentList: {
        newTargetValue: accessory.thermoMode,
      },
    });

    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: "setTemperature",
      argumentList: {
        targetMode: accessory.thermoMode,
        newTargetValue:
          accessory.thermoMode == "thermoModeCool"
            ? accessory.coolSetPoint
            : accessory.thermoMode == "thermoModeHeat"
              ? accessory.heatSetPoint
              : accessory.temperature,
      },
    });
  } else if (className(accessory.category) == "HHAccessoryAdvThermostat") {
    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: accessory.onStatus ? "actionOn" : "actionOff",
    });

    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: "setTargetPoint",
      argumentList: {
        newTargetValue: accessory.targetSetPoint,
      },
    });
  } else if (className(accessory.category) == "HHAccessoryWindowCovering") {
    actions.push({
      accessory: {
        type: className(accessory.category),
        properties: { ...accessory },
      },
      service: accessory.actionMode
        ? "setWindowCoveringAction"
        : "setDeviceLevel",
      argumentList: {
        newTargetValue: accessory.actionMode
          ? accessory.actionMode
          : accessory.level,
      },
    });
  }

  return actions;
}
