import request from '@/api/relay-service'
import { METHOD_ADD_SECTION, METHOD_EDIT_SECTION, METHOD_GET_SECTIONS, METHOD_REMOVE_SECTION, METHOD_SYNC_SECTIONS } from '../store/method-names'

export function getSections(){
    return request({
        params: {
            action: METHOD_GET_SECTIONS
        },
        method: 'get'
    })
}

export function syncSections(data){
    return request({
        params: {
            action: METHOD_SYNC_SECTIONS
        },
        method: 'post',
        data
    })
}

export function addSection(data){
    return request({
        params: {
            action: METHOD_ADD_SECTION
        },
        method: 'post',
        data
    })
}

export function editSection(data){
    return request({
        params: {
            action: METHOD_EDIT_SECTION
        },
        method: 'post',
        data
    })
}

export function removeSection(data){
    return request({
        params: {
            action: METHOD_REMOVE_SECTION,
        },
        method: 'post',
        data
    })
}