var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "rootlayout" },
    [
      _vm.$store.getters.isConnectedDevice ||
      _vm.$store.getters.isSidebarVisible
        ? _c("app-sidebar")
        : _vm._e(),
      _c(
        "el-container",
        [
          _c(
            "el-header",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.$store.getters.technicalAccessCode ||
                    _vm.$store.getters.isLocalMode ||
                    _vm.$store.getters.roles.maintenance,
                  expression:
                    "$store.getters.technicalAccessCode || $store.getters.isLocalMode || $store.getters.roles.maintenance"
                }
              ],
              attrs: { height: "80px" }
            },
            [_c("app-topbar")],
            1
          ),
          _c("el-main", [_c("router-view")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }