import request from '@/api/relay-service'
import { METHOD_GET_ARMING_SETTINGS, METHOD_GET_SECURITY_PANEL_SETTINGS, METHOD_SAVE_ARMING_SETTINGS, METHOD_SAVE_SECURITY_PANEL_SETTINGS } from '../store/method-names'

export function getArmingSettings(){
    return request({
        params: {
            action: METHOD_GET_ARMING_SETTINGS
        },
        method: 'get'
    })
}

export function editArmingSettings(data){
    return request({
        params: {
            action: METHOD_SAVE_ARMING_SETTINGS
        },
        method: 'post',
        data
    })
}

export function getSecurityPanelSettings(){
    return request({
        params: {
            action: METHOD_GET_SECURITY_PANEL_SETTINGS
        },
        method: 'get'
    })
}

export function editSecurityPanelSettings(data){
    return request({
        params: {
            action: METHOD_SAVE_SECURITY_PANEL_SETTINGS
        },
        method: 'post',
        data
    })
}