import request from '@/api/relay-service'
import { METHOD_GET_SECURITY_SETTINGS, METHOD_SAVE_SECURITY_SETTINGS } from '../store/method-names'

export function getSecuritySettings(){
    return request({
        params: {
            action: METHOD_GET_SECURITY_SETTINGS
        },
        method: 'get'
    })
}

export function editSecuritySettings(data){
    return request({
        params: {
            action: METHOD_SAVE_SECURITY_SETTINGS
        },
        method: 'post',
        data
    })
}