import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router/router'


import 'element-ui/lib/theme-chalk/reset.css'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
//import trLocale from 'element-ui/lib/locale/lang/tr-TR'
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './i18n'

//import './assets/css/font-awesome.min.css'
import './assets/css/all.css'


import './assets/flags.min.css'
import InputMask from 'inputmask'
import './style/element-ui-customization.css'

Vue.use(ElementUI, { locale });



Vue.directive('mask', {
  bind: function (el, binding) {
    InputMask(binding.value).mask(el.getElementsByTagName('INPUT')[0])
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

