import {
  METHOD_GET_SECURITY_SETTINGS,
  METHOD_SAVE_SECURITY_SETTINGS
} from "../method-names";
import { getSecuritySettings, editSecuritySettings } from "@/api/security";
import {MUTATION_SET_SECURITY_SETTINGS} from "../mutation-types";
import networkModule from "./network";

export const securityModule = {
  state: {
    securitySettings: {}
  },

  getters: {
    securitySettings: state => state.securitySettings
  },

  mutations: {
    [MUTATION_SET_SECURITY_SETTINGS] (state, settings) {
      state.securitySettings = settings
    },
  },

  actions: {
    getSecuritySettings ({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_SECURITY_SETTINGS})
    },

    saveSecuritySettings ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SAVE_SECURITY_SETTINGS, data: payload })
    },


    onEditSecuritySettings ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_SECURITY_SETTINGS })
    },

    onReceivedSecuritySettings ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_SECURITY_SETTINGS })
    },
  }
}

export const securityAPI = {
  state: {
    securitySettings: {}
  },

  getters: {
    securitySettings: state => state.securitySettings
  },

  mutations: {
    [MUTATION_SET_SECURITY_SETTINGS] (state, settings) {
      state.securitySettings = settings
    },
  },

  actions: {
    getSecuritySettings ({dispatch}) {
      return dispatch('runAction', { action: getSecuritySettings, mutation: MUTATION_SET_SECURITY_SETTINGS })
    },

    saveSecuritySettings ({dispatch}, payload) {
      return dispatch('runAction', { action: editSecuritySettings, data: payload, mutation: MUTATION_SET_SECURITY_SETTINGS })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? securityModule : securityAPI;