import request from '@/api/relay-service'
import { METHOD_ADD_TRIGGER, METHOD_EDIT_TRIGGER, METHOD_GET_TRIGGERS, METHOD_REMOVE_TRIGGER, METHOD_SYNC_TRIGGERS } from '../store/method-names'

export function getTriggers(){
    return request({
        params: {
            action: METHOD_GET_TRIGGERS
        },
        method: 'get'
    })
}

export function syncTriggers(data){
    return request({
        params: {
            action: METHOD_SYNC_TRIGGERS
        },
        method: 'post',
        data
    })
}

export function addTrigger(data){
    return request({
        params: {
            action: METHOD_ADD_TRIGGER
        },
        method: 'post',
        data
    })
}

export function editTrigger(data){
    return request({
        params: {
            action: METHOD_EDIT_TRIGGER
        },
        method: 'post',
        data
    })
}

export function removeTrigger(id){
    return request({
        params: {
            action: METHOD_REMOVE_TRIGGER,
            id: id
        },
        method: 'delete'
    })
}