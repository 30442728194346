<template>
  <div >
  <el-row class="flexbox">
    <el-col :span="8">
      <div class="breadcrumb-layout">
        <span>CoreOS 4.0 Web Interface</span>
      </div>
    </el-col>
    <el-col :span="4">
      <el-input v-show="false"
        v-model="input1"
        class="w-50 m-2 search-box"
        size="large"
        placeholder="Search"
        :prefix-icon="Search"
      />
    </el-col>

    <el-col :span="2" :offset="5">
      <div class="lang-menu">
        <el-dropdown trigger="click" @command="selectLanguage">
          <span class="el-dropdown-link"><img src="@/assets/blank.gif" :class="currentLang.icon"> {{ $t(currentLang.title) }}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="lang" v-for="lang in langs" :key="lang.code"><img src="@/assets/blank.gif" :class="lang.icon"> {{ $t(lang.title) }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-col>

    <el-col :span="4">
      <div class="user-menu">
        <el-dropdown split-button type="default" trigger="click" @command="onClickUserMenu">
          <span class="el-dropdown-link"><i class="fa fa-user-circle"></i> {{ $store.getters.username }}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-show="false" command="ie">{{ $t('topbar.import-export') }}</el-dropdown-item>
            <el-dropdown-item command="pwd">{{ $t('topbar.password-change') }}</el-dropdown-item>
            <el-dropdown-item v-show="$store.getters.isConnectedDevice || $store.getters.isLocalMode" command="reboot">{{ $t('topbar.reboot') }}</el-dropdown-item>
            <el-dropdown-item v-show="$store.getters.isConnectedDevice" command="connectDevice">{{ $t('topbar.change-device') }}</el-dropdown-item>
            <el-dropdown-item command="logout">{{ $t('topbar.logout') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-col>

    <PasswordChangeDialog ref="pwdDialog" :visible="pwdDialogVisible" @onChangePassword="onChangePassword" @onCancelDialog="pwdDialogVisible = false"></PasswordChangeDialog>

  </el-row>
  <!-- <el-row style="margin-top: 10px; margin-bottom: 20px;">
    <el-col :span="24">
      <div class="breadcrumb-layout">
        <el-breadcrumb separator="/">
          <template v-for="(item, index) in $route.matched">
            <el-breadcrumb-item v-if="item.meta.title && index == $route.matched.length - 1" :key="item.path">
              {{ $t('router.' + item.meta.title) }}
            </el-breadcrumb-item>
          </template>
        </el-breadcrumb>
      </div>
    </el-col>
  </el-row> -->
</div>
</template>

<script>

  import router from "@/router/router";
import PasswordChangeDialog from "./PasswordChangeDialog"

  export default {
    name: "Topbar",

    components: {
      PasswordChangeDialog
    },

    data: function(){
      return {
        langs: [
          {code: "tr", title: "topbar.turkish", icon: "flag flag-tr"},
          {code: "en", title: "topbar.english", icon: "flag flag-us"}
        ],

        pwdDialogVisible: false,
        input1:'',
      }
    },

    computed: {
      currentLang(){
        return this.langs.find(lang => lang.code == this.$i18n.locale)
      }
    },

    watch: {
      "$i18n.locale": function (value) {
        localStorage.setItem("lang", value);
      }
    },


    methods: {

      onClickUserMenu(command){
        switch (command) {
          case 'ie':
            break;
          case 'reboot':
            this.$confirm(this.$t('alert.message.confirm-reboot'), this.$t('alert.title.warning'), {
              confirmButtonText: this.$t('alert.button.ok'),
              cancelButtonText: this.$t('alert.button.cancel'),
              type: 'warning'
            }).then(() => {
              this.$store.dispatch('rebootDevice')
            })

            break;
          case 'pwd':
            this.pwdDialogVisible = true
            break;
          case 'connectDevice':
            this.$store.dispatch('resetDeviceInfo');
            this.$router.push({name: 'AccessCode'})
            break;
          case 'logout':
            this.onLogout(command)
            break;
        }
      },

      onChangePassword(success) {
        this.pwdDialogVisible = false
        if (success)
          this.$store.dispatch('logout')
      },

      selectLanguage(lang) {
        this.currentLang = lang
        this.$i18n.locale = lang.code
      },

      onGetDrivers(event) {
        this.$store.dispatch('getDrivers').then(response => {
          this.$message({
            type: "success", message: this.$t(response.message)
          })
        }).catch(error => {
          this.$message({
            type: "error", message: this.$t(error)
          })
        })
      },

      onLogout(event){
        this.$store.dispatch('logout')
      }
    },
  }
</script>

<style>
  .hamburger {
    width: 40px;
    height: 100%;
    font-size: xx-large;
    color: dimgray;
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .user-menu {
    float: right;
    cursor: pointer;
    box-shadow: 4px 4px 10px rgba(184,184,184, 0.5);
  }

  .lang-menu {
    cursor: pointer;
    text-align: center;
  }

  .breadcrumb-layout {
    font-size: 18px;
    font-family:"SF Medium";
    color: #606266;
  }
  .flexbox{
    display: flex !important;
    align-items: center;
    padding: 25px 0 25px 0;
  }
  /* .el-breadcrumb {
    font-size: 36px !important; 
    font-family:"SF Medium";
    color: #000000;
  } */
</style>