import {
  METHOD_ADD_TRIGGER,
  METHOD_EDIT_TRIGGER,
  METHOD_GET_TRIGGERS,
  METHOD_REMOVE_TRIGGER, METHOD_SYNC_TRIGGERS
} from "../method-names";

import { 
  getTriggers, 
  syncTriggers, 
  addTrigger, 
  editTrigger, 
  removeTrigger 
} from "@/api/trigger";

import {
  MUTATION_ADD_TRIGGER,
  MUTATION_EDIT_TRIGGER,
  MUTATION_REMOVE_TRIGGER,
  MUTATION_SET_TRIGGERS
} from "../mutation-types";

const automationModule = {
  state: {
    testTrigger: {
      name: "Trigger 1",
      condition: {
        id: 1,
        symbol: "AND",
        conditions: [
          {
            id: 1,
            symbol: "=",
            category: "Accessory",
            identifier: "Gaz Sensörü",
            property: "Trip",
            value: true
          },
          {
            id: 2,
            symbol: "=",
            category: "Accessory",
            identifier: "Salon Aydınlatma",
            property: "Switch",
            value: false
          },
          {
            id: 3,
            symbol: "OR",
            conditions: [
              {
                id: 1,
                symbol: ">=",
                category: "Accessory",
                identifier: "Klima",
                property: "Temperature",
                value: 25
              },
              {
                id: 2,
                symbol: "=",
                category: "Security",
                property: "Mode",
                value: "ArmedAway"
              },
              {
                id: 3,
                symbol: "=",
                category: "KnxAddress",
                identifier: "1/1/2",
                property: "readValue",
                value: 34
              }
            ]
          }
        ]
      },
      actions: [
        {
          "method": "runScene",
          "params": {
            "id": 1
          }
        },
        {
          "method": "setPowerOn",
          "params": {
            "id": 2,
            "switch": false
          }
        },
        {
          "method": "delay",
          "params": {
            "time": 1000
          }
        },
        {
          "method": "sendKnxValue",
          "params": {
            "dpt": "1.001",
            "address": "1/0/109",
            "value": 1
          }
        },
        {
          "method": "callContact",
          "params": {
            "id": 1
          }
        },
      ],
      executable: true,
      createDate: "",
      lastModifiedDate: "",
      lastExecutionDate: ""
    },

    triggers: [],
    triggerData: {
      removeCounter: 0,
      condition: {},
      actions: []
    }
  },

  getters: {
    triggers: state => state.triggers,
    triggerCount: state => state.triggers.length,
    triggerData: state => state.triggerData,
    triggerTestData: state => state.testTrigger,
  },

  mutations: {
    [MUTATION_SET_TRIGGERS] (state, triggers) {
      state.triggers = triggers
    },

    [MUTATION_ADD_TRIGGER] (state, trigger) {
      state.triggers.push(trigger)
    },

    [MUTATION_EDIT_TRIGGER] (state, newTrigger) {
      state.triggers = state.triggers.map(trigger => {
        return trigger.id == newTrigger.id ? newTrigger : trigger
      })
    },

    [MUTATION_REMOVE_TRIGGER] (state, triggerId) {
      state.triggers = state.triggers.filter(trigger => trigger.id != triggerId)
    },

    setTriggerData (state, trigger) {
      state.triggerData = trigger
    },

    resetTriggerData (state) {
      state.triggerData = {
        removeCounter: 0,
        condition: {},
        actions: []
      }
    },


    addCondition (state, id) {
      state.triggerData.condition = {
        id: id
      }
    },

    saveCondition (state, data) {
      console.log("saveCondition", JSON.stringify(data))

      if (data.path.length == 0)
        state.triggerData.condition = data.condition
      else
        setValue(data.path, data.condition, state.triggerData.condition)
    },

    removeCondition (state) {
      if (state.triggerData.condition.removed || !state.triggerData.condition.hasOwnProperty("conditions")) {
        state.triggerData.condition = {}
      } else if (state.triggerData.condition.hasOwnProperty("conditions")) {
        remove(state.triggerData.condition, state.triggerData.condition)
        checkAndFix(state.triggerData.condition, state.triggerData.condition)
        state.triggerData.removeCounter = state.triggerData.removeCounter + 1

        console.log("remove", state.triggerData.condition)
      }

    },

    addTriggerAction (state, action) {
      state.triggerData.actions.push(action)
    },

    removeTriggerAction (state, index) {
      state.triggerData.actions.splice(index, 1)
    },

  },

  actions: {
    /**
     * request method for all Triggers
     * */
    getTriggers({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_TRIGGERS})
    },

    /**
     * request method for sync Triggers
     *
     * @param payload trigger data
     * */
    syncTriggers({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SYNC_TRIGGERS, data: payload })
    },

    /**
     * request method for adding Trigger
     *
     * @param payload scene data
     * */
    addTrigger({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ADD_TRIGGER, data: payload })
    },

    /**
     * request method for editting Trigger
     *
     * @param payload scene data
     * */
    editTrigger({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_EDIT_TRIGGER, data: payload })
    },

    /**
     * request method for removing Trigger
     *
     * @param payload scene data
     * */
    removeTrigger({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_REMOVE_TRIGGER, data: payload })
    },


    /**
     * response method for {@link getScenes}
     *
     * @param event data from socket
     * */
    onReceivedTriggers ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_TRIGGERS})
    },

    /**
     * response method for {@link syncTriggers}
     *
     * @param event data from socket
     * */
    onSyncTriggers ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_TRIGGERS})
    },

    /**
     * response method for {@link addTrigger}
     *
     * @param event data from socket
     * */
    onAddTrigger ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ADD_TRIGGER})
    },

    /**
     * response method for {@link editTrigger}
     *
     * @param event data from socket
     * */
    onEditTrigger ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_TRIGGER})
    },

    /**
     * response method for {@link removeTrigger}
     *
     * @param event data from socket
     * */
    onRemoveTrigger ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REMOVE_TRIGGER})
    }
  }
}

const automationAPI = {
  state: {
    testTrigger: {
      name: "Trigger 1",
      condition: {
        id: 1,
        symbol: "AND",
        conditions: [
          {
            id: 1,
            symbol: "=",
            category: "Accessory",
            identifier: "Gaz Sensörü",
            property: "Trip",
            value: true
          },
          {
            id: 2,
            symbol: "=",
            category: "Accessory",
            identifier: "Salon Aydınlatma",
            property: "Switch",
            value: false
          },
          {
            id: 3,
            symbol: "OR",
            conditions: [
              {
                id: 1,
                symbol: ">=",
                category: "Accessory",
                identifier: "Klima",
                property: "Temperature",
                value: 25
              },
              {
                id: 2,
                symbol: "=",
                category: "Security",
                property: "Mode",
                value: "ArmedAway"
              },
              {
                id: 3,
                symbol: "=",
                category: "KnxAddress",
                identifier: "1/1/2",
                property: "readValue",
                value: 34
              }
            ]
          }
        ]
      },
      actions: [
        {
          "method": "runScene",
          "params": {
            "id": 1
          }
        },
        {
          "method": "setPowerOn",
          "params": {
            "id": 2,
            "switch": false
          }
        },
        {
          "method": "delay",
          "params": {
            "time": 1000
          }
        },
        {
          "method": "sendKnxValue",
          "params": {
            "dpt": "1.001",
            "address": "1/0/109",
            "value": 1
          }
        },
        {
          "method": "callContact",
          "params": {
            "id": 1
          }
        },
      ],
      executable: true,
      createDate: "",
      lastModifiedDate: "",
      lastExecutionDate: ""
    },

    triggers: [],
    triggerData: {
      removeCounter: 0,
      condition: {},
      actions: []
    }
  },

  getters: {
    triggers: state => state.triggers,
    triggerCount: state => state.triggers.length,
    triggerData: state => state.triggerData,
    triggerTestData: state => state.testTrigger,
  },

  mutations: {
    [MUTATION_SET_TRIGGERS] (state, triggers) {
      state.triggers = triggers
    },

    [MUTATION_ADD_TRIGGER] (state, trigger) {
      state.triggers.push(trigger)
    },

    [MUTATION_EDIT_TRIGGER] (state, newTrigger) {
      state.triggers = state.triggers.map(trigger => {
        return trigger.id == newTrigger.id ? newTrigger : trigger
      })
    },

    [MUTATION_REMOVE_TRIGGER] (state, triggerId) {
      state.triggers = state.triggers.filter(trigger => trigger.id != triggerId)
    },

    setTriggerData (state, trigger) {
      state.triggerData = trigger
    },

    resetTriggerData (state) {
      state.triggerData = {
        removeCounter: 0,
        condition: {},
        actions: []
      }
    },


    addCondition (state, id) {
      state.triggerData.condition = {
        id: id
      }
    },

    saveCondition (state, data) {
      console.log("saveCondition", JSON.stringify(data))

      if (data.path.length == 0)
        state.triggerData.condition = data.condition
      else
        setValue(data.path, data.condition, state.triggerData.condition)
    },

    removeCondition (state) {
      if (state.triggerData.condition.removed || !state.triggerData.condition.hasOwnProperty("conditions")) {
        state.triggerData.condition = {}
      } else if (state.triggerData.condition.hasOwnProperty("conditions")) {
        remove(state.triggerData.condition, state.triggerData.condition)
        checkAndFix(state.triggerData.condition, state.triggerData.condition)
        state.triggerData.removeCounter = state.triggerData.removeCounter + 1

        console.log("remove", state.triggerData.condition)
      }

    },

    addTriggerAction (state, action) {
      state.triggerData.actions.push(action)
    },

    removeTriggerAction (state, index) {
      state.triggerData.actions.splice(index, 1)
    },

  },

  actions: {
    /**
     * request method for all Triggers
     * */
    getTriggers({dispatch}) {
      return dispatch('runAction', { action: getTriggers, mutation: MUTATION_SET_TRIGGERS })
    },

    /**
     * request method for sync Triggers
     *
     * @param payload trigger data
     * */
    syncTriggers({dispatch}, payload) {
      return dispatch('runAction', { action: syncTriggers, data: payload, mutation: MUTATION_SET_TRIGGERS })
    },

    /**
     * request method for adding Trigger
     *
     * @param payload scene data
     * */
    addTrigger({dispatch}, payload) {
      return dispatch('runAction', { action: addTrigger, data: payload, mutation: MUTATION_ADD_TRIGGER })
    },

    /**
     * request method for editting Trigger
     *
     * @param payload scene data
     * */
    editTrigger({dispatch}, payload) {
      return dispatch('runAction', { action: editTrigger, data: payload, mutation: MUTATION_EDIT_TRIGGER })
    },

    /**
     * request method for removing Trigger
     *
     * @param payload scene data
     * */
    removeTrigger({dispatch}, payload) {
      return dispatch('runAction', { action: removeTrigger, data: payload, mutation: MUTATION_REMOVE_TRIGGER })
    }
  }
}


let setValue = (propertyPath, value, obj) => {
  console.log("setterValue", propertyPath, JSON.stringify(value, null, 2), JSON.stringify(obj, null, 2))
  if (propertyPath.length == 0) {
    automationModule.state.triggerData.condition = value

    console.log("setterval2", JSON.stringify(obj, null, 2))
    return true
  }

  let properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(".")

  if (properties.length > 1) {

    if (!obj.hasOwnProperty(properties[0]) || typeof obj[properties[0]] !== "object") obj[properties[0]] = {}

    return setValue(properties.slice(1), value, obj[properties[0]])
  } else {
    obj[properties[0]] = value
    return true
  }
}


let getPath = (a, obj) => {
  console.log("getPath", a, JSON.stringify(obj, null, 2))

  for(var key in obj) {
    if(obj.hasOwnProperty(key)) {
      if(key == "id" && a == obj[key]) return key;

      else if(obj[key] && typeof obj[key] === "object") {
        var path = getPath(a, obj[key]);
        if(path) return key + "." + path;
      }
    }
  }
}


let checkAndFix = (root, data) => {

  console.log("checkAndFix", JSON.stringify(data, null, 2))

  if (data.conditions) {
    for (let i = 0; i < data.conditions.length; i++) {
      if (data.conditions.length == 1) {

        let path = getPath(data.id, {...root})

        if (path.endsWith(".id"))
          path = path.replace(".id", '')
        else if (path.endsWith("id"))
          path = path.replace("id", '')

        setValue(path, data.conditions[0], root)
      }

      checkAndFix({...root}, data.conditions[i])
    }
  }
}



let remove = (root, data) => {
  if (data.conditions) {
    for (let i = 0; i < data.conditions.length; i++) {
      if (data.conditions[i].removed) {
        data.conditions.splice(i, 1)

        if (data.conditions.length == 0) {
          data.removed = true
          remove(root, {...root})
        }

        break
      } else {
        remove(root, data.conditions[i])
      }

    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? automationModule : automationAPI;