import request from '@/api/relay-service'
import { METHOD_ADD_ROOM, METHOD_EDIT_ROOM, METHOD_GET_ROOMS, METHOD_REMOVE_ROOM, METHOD_SYNC_ROOMS } from '../store/method-names'

/**
 * get api for rooms
 * @returns room list
 */
export function getRooms(){
    return request({
        params: {
            action: METHOD_GET_ROOMS
        },
        method: 'get'
    })
}

/**
 * syncronize rooms with Touch Panel
 * @param {room list} data 
 * @returns room list that imported
 */
export function syncRooms(data){
    return request({
        params: {
            action: METHOD_SYNC_ROOMS
        },
        method: 'post',
        data
    })
}

export function addRoom(data){
    return request({
        params: {
            action: METHOD_ADD_ROOM
        },
        method: 'post',
        data
    })
}

export function editRoom(data){
    return request({
        params: {
            action: METHOD_EDIT_ROOM
        },
        method: 'post',
        data
    })
}

export function removeRoom(data){
    return request({
        params: {
            action: METHOD_REMOVE_ROOM,
        },
        method: 'post',
        data
    })
}