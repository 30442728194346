import {MUTATION_ADD_SCENE, MUTATION_EDIT_SCENE, MUTATION_REMOVE_SCENE, MUTATION_SET_SCENES} from "../mutation-types";
import {
  METHOD_ADD_SCENE,
  METHOD_EDIT_SCENE,
  METHOD_GET_SCENES,
  METHOD_REMOVE_SCENE,
  METHOD_SYNC_SCENES
} from "../method-names";
import { 
  getScenes, 
  syncScenes, 
  addScene, 
  editScene, 
  removeScene 
} from "@/api/scene";

import {DEFAULT_ROOM_ID} from "../../utils/accessory-utils";


const sceneModule = {
  state: {
    scenes: [],

    sceneFormData: {
      id: DEFAULT_ROOM_ID,
      editMode: false,
      actionGroupEditMode: false,
      actionGroup: {
        delay: 0,
        actionList:[]
      },
      selectedAccessories: [],
      scene: {
        modifiable: true,
        actionSet: []
      }
    }
  },

  getters: {
    scenes: state => state.scenes,
    sceneCount: state => state.scenes.length,

    sceneFormData: state => state.sceneFormData,
  },

  mutations: {
    [MUTATION_SET_SCENES] (state, scenes) {
      state.scenes = scenes
    },

    [MUTATION_ADD_SCENE] (state, scene) {
      state.scenes.push(scene)
    },

    [MUTATION_EDIT_SCENE] (state, newScene) {
      state.scenes = state.scenes.map(scene => {
        return scene.id == newScene.id ? newScene : scene
      })
    },

    [MUTATION_REMOVE_SCENE] (state, sceneId) {
      state.scenes = state.scenes.filter(scene => scene.id != sceneId)
    },


    resetSceneFormData (state) {
      state.sceneFormData = {
        id: DEFAULT_ROOM_ID,
        editMode: false,
        actionGroupEditMode: false,
        actionGroup: {
          delay: 0
        },
        selectedAccessories: [],
        scene: {
          modifiable: true,
          actionSet: []
        }
      }
    },

    setSceneEditMode (state, editMode) {
      state.sceneFormData.editMode = editMode
    },

    setActionGroupEditMode (state, editMode) {
      state.sceneFormData.actionGroupEditMode = editMode
    },

    setSceneData (state, sceneData) {
      console.log("scene", sceneData)
      state.sceneFormData.scene = sceneData
    },

    setActionGroupData (state, actionGroupData) {
      console.log("action", actionGroupData)
      state.sceneFormData.actionGroup = actionGroupData
    },

    setSelectedAccessories (state, selectedAccessories) {
      console.log("selected", selectedAccessories)
      state.sceneFormData.selectedAccessories = selectedAccessories
    }
  },

  actions: {
    /**
     * request method for all scenes
     * */
    getScenes({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_SCENES})
    },

    /**
     * request method for sync Scenes
     *
     * @param payload scene data
     * */
    syncScenes({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SYNC_SCENES, data: payload })
    },

    /**
     * request method for adding Scene
     *
     * @param payload scene data
     * */
    addScene({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ADD_SCENE, data: payload })
    },

    /**
     * request method for editting Scene
     *
     * @param payload scene data
     * */
    editScene({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_EDIT_SCENE, data: payload })
    },

    /**
     * request method for removing Scene
     *
     * @param payload scene data
     * */
    removeScene({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_REMOVE_SCENE, data: payload })
    },


    /**
     * response method for {@link getScenes}
     *
     * @param event data from socket
     * */
    onReceivedScenes ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_SCENES})
    },

    /**
     * response method for {@link syncScenes}
     *
     * @param event data from socket
     * */
    onSyncScenes ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_SCENES})
    },

    /**
     * response method for {@link addScene}
     *
     * @param event data from socket
     * */
    onAddScene ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ADD_SCENE})
    },

    /**
     * response method for {@link editScene}
     *
     * @param event data from socket
     * */
    onEditScene ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_SCENE})
    },

    /**
     * response method for {@link removeScene}
     *
     * @param event data from socket
     * */
    onRemoveScene ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REMOVE_SCENE})
    }
  }
}

const sceneAPI = {
  state: {
    scenes: [],

    sceneFormData: {
      id: DEFAULT_ROOM_ID,
      editMode: false,
      actionGroupEditMode: false,
      actionGroup: {
        delay: 0
      },
      selectedAccessories: [],
      scene: {
        modifiable: true,
        actionSet: []
      }
    }
  },

  getters: {
    scenes: state => state.scenes,
    sceneCount: state => state.scenes.length,

    sceneFormData: state => state.sceneFormData,
  },

  mutations: {
    [MUTATION_SET_SCENES] (state, scenes) {
      state.scenes = scenes
    },

    [MUTATION_ADD_SCENE] (state, scene) {
      state.scenes.push(scene)
    },

    [MUTATION_EDIT_SCENE] (state, newScene) {
      state.scenes = state.scenes.map(scene => {
        return scene.id == newScene.id ? newScene : scene
      })
    },

    [MUTATION_REMOVE_SCENE] (state, sceneId) {
      state.scenes = state.scenes.filter(scene => scene.id != sceneId)
    },


    resetSceneFormData (state) {
      state.sceneFormData = {
        id: DEFAULT_ROOM_ID,
        editMode: false,
        actionGroupEditMode: false,
        actionGroup: {
          delay: 0
        },
        selectedAccessories: [],
        scene: {
          modifiable: true,
          actionSet: []
        }
      }
    },

    setSceneEditMode (state, editMode) {
      state.sceneFormData.editMode = editMode
    },

    setActionGroupEditMode (state, editMode) {
      state.sceneFormData.actionGroupEditMode = editMode
    },

    setSceneData (state, sceneData) {
      console.log("scene", sceneData)
      state.sceneFormData.scene = sceneData
    },

    setActionGroupData (state, actionGroupData) {
      console.log("action", actionGroupData)
      state.sceneFormData.actionGroup = actionGroupData
    },

    setSelectedAccessories (state, selectedAccessories) {
      console.log("selected", selectedAccessories)
      state.sceneFormData.selectedAccessories = selectedAccessories
    }
  },

  actions: {
    /**
     * request method for all scenes
     * */
    getScenes({dispatch}) {
      return dispatch('runAction', { action: getScenes, mutation: MUTATION_SET_SCENES })
    },

    /**
     * request method for sync Scenes
     *
     * @param payload scene data
     * */
    syncScenes({dispatch}, payload) {
      return dispatch('runAction', { action: syncScenes, data: payload, mutation: MUTATION_SET_SCENES })
    },

    /**
     * request method for adding Scene
     *
     * @param payload scene data
     * */
    addScene({dispatch}, payload) {
      return dispatch('runAction', { action: addScene, data: payload, mutation: MUTATION_ADD_SCENE })
    },

    /**
     * request method for editting Scene
     *
     * @param payload scene data
     * */
    editScene({dispatch}, payload) {
      return dispatch('runAction', { action: editScene, data: payload, mutation: MUTATION_EDIT_SCENE })
    },

    /**
     * request method for removing Scene
     *
     * @param payload scene data
     * */
    removeScene({dispatch}, payload) {
      return dispatch('runAction', { action: removeScene, data: payload, mutation: MUTATION_REMOVE_SCENE })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? sceneModule : sceneAPI;