import {
  METHOD_ADD_DRIVER,
  METHOD_EDIT_DRIVER,
  METHOD_GET_DRIVERS,
  METHOD_REMOVE_DRIVER, METHOD_SYNC_DRIVERS,
  METHOD_REFRESH_DRIVER
} from "../method-names";

import { 
  getDrivers, 
  addDriver, 
  removeDriver, 
  editDriver, 
  syncDrivers, 
  refreshDriver 
} from "@/api/driver";

import {
  MUTATION_ADD_DRIVER,
  MUTATION_REMOVE_DRIVER,
  MUTATION_EDIT_DRIVER,
  MUTATION_SET_DRIVERS,
  MUTATION_REFRESH_DRIVER
} from "../mutation-types";

const driverModule = {
  state: {
    drivers: []
  },

  getters: {
    driverCount (state) {
      return state.drivers.length
    },

    drivers: state => {
      return state.drivers
    }
  },

  mutations: {
    [MUTATION_SET_DRIVERS] (state, drivers) {
      state.drivers = drivers
    },

    [MUTATION_REFRESH_DRIVER] (state, drivers) {
      
    },

    [MUTATION_ADD_DRIVER] (state, driver) {
      state.drivers.push(driver);
    },

    [MUTATION_EDIT_DRIVER] (state, payload) {
      state.drivers = state.drivers.map(driver => {
        return driver.id == payload.id ? payload : driver
      })
    },

    [MUTATION_REMOVE_DRIVER] (state, driverId) {
      state.drivers = state.drivers.filter(driver => driver.id != driverId)
    }
  },

  actions: {
    /**
    * request method for drivers
    * */
    getDrivers ({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_DRIVERS })
    },


    /**
     * request method for sync drivers
     *
     * @param payload drivers data
     * */
    syncDrivers ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SYNC_DRIVERS, data: payload })
    },


    /**
    * request method for adding driver
    *
    * @param payload driver data
    * */
    addDriver ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ADD_DRIVER, data: payload })
    },

    /**
    * request method for editting driver
    *
    * @param payload driver data
    * */
    editDriver ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_EDIT_DRIVER, data: payload })
    },

    /**
     * request method for removing driver
     *
     * @param payload driver data
     * */
    removeDriver ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_REMOVE_DRIVER, data: payload })
    },


    /**
     * request method to refresh driver
     *
     * @param payload driver data
     * */
    refreshDriver ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_REFRESH_DRIVER, data: payload })
    },    

    /**
     * response method for {@link refreshDriver}
     *
     * @param event data from socket
     * */
    onRefreshDriver ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REFRESH_DRIVER })
    },

    /**
     * response method for {@link getDrivers}
     *
     * @param event data from socket
     * */
    onReceivedDrivers ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_DRIVERS })
    },


    /**
     * response method for {@link syncDrivers}
     *
     * @param event data from socket
     * */
    onSyncDrivers ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_DRIVERS })
    },


    /**
     * response method for {@link addDriver}
     *
     * @param event data from socket
     * */
    onAddDriver ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ADD_DRIVER })
    },

    /**
     * response method for {@link editDriver}
     *
     * @param event data from socket
     * */
    onEditDriver ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_DRIVER })
    },

    /**
     * response method for {@link removeDriver}
     *
     * @param event data from socket
     * */
    onRemoveDriver ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REMOVE_DRIVER })
    }
  }
}

const driverAPI = {
  state: {
    drivers: []
  },

  getters: {
    driverCount (state) {
      return state.drivers.length
    },

    drivers: state => {
      return state.drivers
    }
  },

  mutations: {
    [MUTATION_SET_DRIVERS] (state, drivers) {
      state.drivers = drivers
    },

    [MUTATION_REFRESH_DRIVER] (state, drivers) {
      
    },

    [MUTATION_ADD_DRIVER] (state, driver) {
      state.drivers.push(driver);
    },

    [MUTATION_EDIT_DRIVER] (state, payload) {
      state.drivers = state.drivers.map(driver => {
        return driver.id == payload.id ? payload : driver
      })
    },

    [MUTATION_REMOVE_DRIVER] (state, driverId) {
      state.drivers = state.drivers.filter(driver => driver.id != driverId)
    }
  },

  actions: {
    /**
    * request method for drivers
    * */
    getDrivers ({dispatch}) {
      return dispatch('runAction', { action: getDrivers, mutation: MUTATION_SET_DRIVERS })
    },


    /**
     * request method for sync drivers
     *
     * @param payload drivers data
     * */
    syncDrivers ({dispatch}, payload) {
      return dispatch('runAction', { action: syncDrivers, data: payload, mutation: MUTATION_SET_DRIVERS })
    },


    /**
    * request method for adding driver
    *
    * @param payload driver data
    * */
    addDriver ({dispatch}, payload) {
      return dispatch('runAction', { action: addDriver, data: payload, mutation: MUTATION_ADD_DRIVER })
    },

    /**
    * request method for editting driver
    *
    * @param payload driver data
    * */
    editDriver ({dispatch}, payload) {
      return dispatch('runAction', { action: editDriver, data: payload, mutation: MUTATION_EDIT_DRIVER })
    },

    /**
     * request method for removing driver
     *
     * @param payload driver data
     * */
    removeDriver ({dispatch}, payload) {
      return dispatch('runAction', { action: removeDriver, data: payload, mutation: MUTATION_REMOVE_DRIVER })
    },


    /**
     * request method to refresh driver
     *
     * @param payload driver data
     * */
    refreshDriver ({dispatch}, payload) {
      return dispatch('runAction', { action: refreshDriver, data: payload, mutation: MUTATION_REFRESH_DRIVER })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? driverModule : driverAPI;