import {METHOD_GET_NETWORK_SETTINGS, METHOD_SAVE_NETWORK_SETTINGS} from "../method-names";
import { getNetworkSettings, editNetworkSettings } from "@/api/network";
import {MUTATION_SET_NETWORK_SETTINGS} from "../mutation-types";

const networkModule = {
  state: {
    ethernetSettings: {}
  },

  getters: {
    ethernetSettings: state => state.ethernetSettings
  },

  mutations: {
    [MUTATION_SET_NETWORK_SETTINGS] (state, settings) {
      state.ethernetSettings = settings
    },
  },

  actions: {
    getNetworkSettings ({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_NETWORK_SETTINGS })
    },

    saveNetworkSettings ({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SAVE_NETWORK_SETTINGS, data: payload })
    },


    onEditNetworkSettings ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_NETWORK_SETTINGS })
    },

    onReceivedNetworkSettings ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_NETWORK_SETTINGS })
    },
  }
}

const networkAPI = {
  state: {
    ethernetSettings: {}
  },

  getters: {
    ethernetSettings: state => state.ethernetSettings
  },

  mutations: {
    [MUTATION_SET_NETWORK_SETTINGS] (state, settings) {
      state.ethernetSettings = settings
    },
  },

  actions: {
    getNetworkSettings ({dispatch}) {
      return dispatch('runAction', { action: getNetworkSettings, mutation: MUTATION_SET_NETWORK_SETTINGS })
    },

    saveNetworkSettings ({dispatch}, payload) {
      return dispatch('runAction', { action: editNetworkSettings, data: payload, mutation: MUTATION_SET_NETWORK_SETTINGS })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? networkModule : networkAPI;