import {
    METHOD_GET_MENU_ITEMS,
    METHOD_EDIT_MENU_ITEMS,
  } from "../method-names";

import { 
  getMenuItems, 
  editMenuItems 
} from "@/api/menu-item";

import {
  MUTATION_SET_MENU_ITEMS,
  MUTATION_EDIT_MENU_ITEMS,
} from "../mutation-types";

  const menuItemsModule = {
    state: {
      menuItems: []
    },
  
    getters: {
      menuItems: state => state.menuItems,
      menuItemCount: state => state.menuItems.length
    },
  
    mutations: {
        [MUTATION_SET_MENU_ITEMS] (state, menuItems) {
            state.menuItems = menuItems
          },
          [MUTATION_EDIT_MENU_ITEMS] (state, menuItems) {
            state.menuItems = menuItems
          },
    },
  
    actions: {
      /**
       * request method for all menu items
       * */
      getMenuItems({dispatch}) {
        return dispatch('executeAction', { method: METHOD_GET_MENU_ITEMS })
      },
      /**
       * response method for {@link getMenuItems}
       *
       * @param event data from socket
       * */
      onReceivedMenuItems ({dispatch}, event) {
        return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_MENU_ITEMS })
      },
      /**
       * request method for edit widgets
       *
       * @param payload room data
       * */
      editMenuItems({dispatch}, payload) {
        return dispatch('executeAction', { method: METHOD_EDIT_MENU_ITEMS, data: payload })
      },
      /**
       * response method for {@link editWidgets}
       *
       * @param event data from socket
       * */
      onEditMenuItems ({dispatch}, event) {
        return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_MENU_ITEMS })
      },      
    }
  }

  const menuItemsAPI = {
    state: {
      menuItems: []
    },
  
    getters: {
      menuItems: state => state.menuItems,
      menuItemCount: state => state.menuItems.length
    },
  
    mutations: {
        [MUTATION_SET_MENU_ITEMS] (state, menuItems) {
            state.menuItems = menuItems
          },
          [MUTATION_EDIT_MENU_ITEMS] (state, menuItems) {
            state.menuItems = menuItems
          },
    },
  
    actions: {
      /**
       * request method for all menu items
       * */
      getMenuItems({dispatch}) {
        return dispatch('runAction', { action: getMenuItems, mutation: MUTATION_SET_MENU_ITEMS })
      },
      /**
       * request method for edit widgets
       *
       * @param payload room data
       * */
      editMenuItems({dispatch}, payload) {
        return dispatch('runAction', { action: editMenuItems, data: payload, mutation: MUTATION_SET_MENU_ITEMS })
      }      
    }
  }
  
export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? menuItemsModule : menuItemsAPI;