import request from '@/api/relay-service'
import { METHOD_ADD_SCENE, METHOD_EDIT_SCENE, METHOD_GET_SCENES, METHOD_REMOVE_SCENE, METHOD_SYNC_SCENES } from '../store/method-names'

export function getScenes(){
    return request({
        params: {
            action: METHOD_GET_SCENES
        },
        method: 'get'
    })
}

export function syncScenes(data){
    return request({
        params: {
            action: METHOD_SYNC_SCENES
        },
        method: 'post',
        data
    })
}

export function addScene(data){
    return request({
        params: {
            action: METHOD_ADD_SCENE
        },
        method: 'post',
        data
    })
}

export function editScene(data){
    return request({
        params: {
            action: METHOD_EDIT_SCENE
        },
        method: 'post',
        data
    })
}

export function removeScene(id){
    return request({
        params: {
            action: METHOD_REMOVE_SCENE,
            id: id
        },
        method: 'delete'
    })
}