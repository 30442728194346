import request from '@/api/relay-service'
import { METHOD_GET_NETWORK_SETTINGS, METHOD_SAVE_NETWORK_SETTINGS } from '../store/method-names'

export function getNetworkSettings(){
    return request({
        params: {
            action: METHOD_GET_NETWORK_SETTINGS
        },
        method: 'get'
    })
}

export function editNetworkSettings(data){
    return request({
        params: {
            action: METHOD_SAVE_NETWORK_SETTINGS
        },
        method: 'post',
        data
    })
}