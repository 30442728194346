var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "flexbox" },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "breadcrumb-layout" }, [
              _c("span", [_vm._v("CoreOS 4.0 Web Interface")])
            ])
          ]),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                staticClass: "w-50 m-2 search-box",
                attrs: {
                  size: "large",
                  placeholder: "Search",
                  "prefix-icon": _vm.Search
                },
                model: {
                  value: _vm.input1,
                  callback: function($$v) {
                    _vm.input1 = $$v
                  },
                  expression: "input1"
                }
              })
            ],
            1
          ),
          _c("el-col", { attrs: { span: 2, offset: 5 } }, [
            _c(
              "div",
              { staticClass: "lang-menu" },
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: { trigger: "click" },
                    on: { command: _vm.selectLanguage }
                  },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _c("img", {
                        class: _vm.currentLang.icon,
                        attrs: { src: require("@/assets/blank.gif") }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t(_vm.currentLang.title)))
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(_vm.langs, function(lang) {
                        return _c(
                          "el-dropdown-item",
                          { key: lang.code, attrs: { command: lang } },
                          [
                            _c("img", {
                              class: lang.icon,
                              attrs: { src: require("@/assets/blank.gif") }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t(lang.title)))
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _c(
              "div",
              { staticClass: "user-menu" },
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: {
                      "split-button": "",
                      type: "default",
                      trigger: "click"
                    },
                    on: { command: _vm.onClickUserMenu }
                  },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _c("i", { staticClass: "fa fa-user-circle" }),
                      _vm._v(" " + _vm._s(_vm.$store.getters.username))
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: { command: "ie" }
                          },
                          [_vm._v(_vm._s(_vm.$t("topbar.import-export")))]
                        ),
                        _c("el-dropdown-item", { attrs: { command: "pwd" } }, [
                          _vm._v(_vm._s(_vm.$t("topbar.password-change")))
                        ]),
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$store.getters.isConnectedDevice ||
                                  _vm.$store.getters.isLocalMode,
                                expression:
                                  "$store.getters.isConnectedDevice || $store.getters.isLocalMode"
                              }
                            ],
                            attrs: { command: "reboot" }
                          },
                          [_vm._v(_vm._s(_vm.$t("topbar.reboot")))]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$store.getters.isConnectedDevice,
                                expression: "$store.getters.isConnectedDevice"
                              }
                            ],
                            attrs: { command: "connectDevice" }
                          },
                          [_vm._v(_vm._s(_vm.$t("topbar.change-device")))]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "logout" } },
                          [_vm._v(_vm._s(_vm.$t("topbar.logout")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("PasswordChangeDialog", {
            ref: "pwdDialog",
            attrs: { visible: _vm.pwdDialogVisible },
            on: {
              onChangePassword: _vm.onChangePassword,
              onCancelDialog: function($event) {
                _vm.pwdDialogVisible = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }