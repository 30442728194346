import request from '@/api/relay-service'
import { METHOD_EDIT_MENU_ITEMS, METHOD_GET_MENU_ITEMS } from '../store/method-names'

export function getMenuItems(){
    return request({
        params: {
            action: METHOD_GET_MENU_ITEMS
        },
        method: 'get'
    })
}


export function editMenuItems(data){
    return request({
        params: {
            action: METHOD_EDIT_MENU_ITEMS
        },
        method: 'post',
        data
    })
}