export const METHOD_LOGIN = "login";
export const METHOD_LOGOUT = "logout";
export const METHOD_EDIT_PASSWORD = "editPassword";

export const METHOD_GET_DRIVERS = "getDrivers";
export const METHOD_SYNC_DRIVERS = "syncDrivers";
export const METHOD_ADD_DRIVER = "addDriver";
export const METHOD_EDIT_DRIVER = "editDriver";
export const METHOD_REMOVE_DRIVER = "removeDriver";
export const METHOD_REFRESH_DRIVER = "refreshDriver"

export const METHOD_GET_ACCESSORIES = "getAccessories";
export const METHOD_SYNC_ACCESSORIES = "syncAccessories";
export const METHOD_ADD_ACCESSORY = "addAccessory";
export const METHOD_EDIT_ACCESSORY = "editAccessory";
export const METHOD_REMOVE_ACCESSORY = "removeAccessory";

export const METHOD_GET_IPCAMERAS = "getIPCameras";
export const METHOD_SYNC_IPCAMERAS = "syncIPCameras";
export const METHOD_ADD_IPCAMERA = "addIPCamera";
export const METHOD_EDIT_IPCAMERA = "editIPCamera";
export const METHOD_REMOVE_IPCAMERA = "removeIPCamera";

export const METHOD_GET_ROOMS = "getRooms";
export const METHOD_SYNC_ROOMS = "syncRooms";
export const METHOD_ADD_ROOM = "addRoom";
export const METHOD_EDIT_ROOM = "editRoom";
export const METHOD_REMOVE_ROOM = "removeRoom";

export const METHOD_GET_SECTIONS = "getSections";
export const METHOD_SYNC_SECTIONS = "syncSections";
export const METHOD_ADD_SECTION = "addSection";
export const METHOD_EDIT_SECTION = "editSection";
export const METHOD_REMOVE_SECTION = "removeSection";

export const METHOD_GET_SCENES = "getScenes";
export const METHOD_SYNC_SCENES = "syncScenes";
export const METHOD_ADD_SCENE = "addScene";
export const METHOD_EDIT_SCENE = "editScene";
export const METHOD_REMOVE_SCENE = "removeScene";

export const METHOD_GET_TRIGGERS = "getTriggers";
export const METHOD_SYNC_TRIGGERS = "syncTriggers";
export const METHOD_ADD_TRIGGER = "addTrigger";
export const METHOD_EDIT_TRIGGER = "editTrigger";
export const METHOD_REMOVE_TRIGGER = "removeTrigger";

export const METHOD_GET_GENERAL_SETTINGS = "getGeneralSettings";
export const METHOD_GET_GENERAL_INFO = "getGeneralInfo";
export const METHOD_SAVE_GENERAL_SETTINGS = "saveGeneralSettings";

export const METHOD_GET_CONTACTS = "getContacts";
export const METHOD_SYNC_CONTACTS = "syncContacts";
export const METHOD_ADD_CONTACT = "addContact";
export const METHOD_EDIT_CONTACT = "editContact";
export const METHOD_REMOVE_CONTACT = "removeContact";
export const METHOD_GET_INTERCOM_SETTINGS = "getIntercomSettings";
export const METHOD_SAVE_INTERCOM_SETTINGS = "saveIntercomSettings";

export const METHOD_GET_WIDGETS = "getWidgets";
export const METHOD_EDIT_WIDGETS = "editWidgets";

export const METHOD_GET_MENU_ITEMS = "getMenuItems";
export const METHOD_EDIT_MENU_ITEMS = "editMenuItems";

export const METHOD_ENABLE_ADB = "enableADB";
export const METHOD_ENABLE_OTG = "enableOTG";
export const METHOD_PLAY_SOUND = "playSound";
export const METHOD_RUN_SIP_ACTION = "executeSipAction";
export const METHOD_REBOOT_DEVICE = "rebootDevice";


export const METHOD_GET_NETWORK_SETTINGS = "getNetworkSettings";
export const METHOD_SAVE_NETWORK_SETTINGS = "saveNetworkSettings";

export const METHOD_GET_SECURITY_SETTINGS = "getSecuritySettings";
export const METHOD_SAVE_SECURITY_SETTINGS = "saveSecuritySettings";

export const METHOD_GET_ARMING_SETTINGS = "getArmingSettings";
export const METHOD_SAVE_ARMING_SETTINGS = "saveArmingSettings";

export const METHOD_GET_SECURITY_PANEL_SETTINGS = "getSecurityPanelSettings"
export const METHOD_SAVE_SECURITY_PANEL_SETTINGS = "saveSecurityPanelSettings"

export const METHOD_GET_LOGS = "getLogMessages";
export const METHOD_GET_RAW_LOGS = "getRawLogMessages";

export const METHOD_GET_RSS_SETTINGS = "getRssSettings"
export const METHOD_EDIT_RSS_SETTINGS = "editRssSettings"