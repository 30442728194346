import {
  METHOD_ADD_CONTACT,
  METHOD_EDIT_CONTACT,
  METHOD_GET_CONTACTS,
  METHOD_GET_INTERCOM_SETTINGS,
  METHOD_REMOVE_CONTACT,
  METHOD_SAVE_INTERCOM_SETTINGS,
  METHOD_SYNC_CONTACTS,
} from "../method-names";

import { 
  getContacts, 
  syncContacts, 
  addContact, 
  editContact, 
  removeContact, 
  getIntercomSettings, 
  editIntercomSettings 
} from "../../api/intercom";

import {
  MUTATION_ADD_CONTACT,
  MUTATION_EDIT_CONTACT,
  MUTATION_REMOVE_CONTACT,
  MUTATION_SET_CONTACTS,
  MUTATION_SET_INTERCOM_SETTINGS,
} from "../mutation-types";

const intercomModule = {
  state: {
    intercomSettingsFormData: {},

    intercomSettings: {
      displayName: "",
      enableAutomaticContactSynchronization: false,
      hideMe: false,
      sipServer: {
        enabled: false,
        serverUrl: "",
        port: 0,
        username: "",
        password: "",
        transportType: 0,
      },
    },

    contacts: [],
  },

  getters: {
    intercomSettings: (state) => state.intercomSettings,
    contacts: (state) =>
      state.contacts.filter((contact) => contact.contactType != "multicast"),
    contactCount: (state) => state.contacts.length,
    intercomSettingsFormData: (state) => state.intercomSettingsFormData,
  },

  mutations: {
    [MUTATION_SET_INTERCOM_SETTINGS](state, settings) {
      state.intercomSettings = settings;
    },

    [MUTATION_SET_CONTACTS](state, contacts) {
      state.contacts = contacts;
    },

    [MUTATION_ADD_CONTACT](state, contact) {
      state.contacts.push(contact);
    },

    [MUTATION_EDIT_CONTACT](state, newContact) {
      console.log("mutation edit contact : " + newContact.favorite);
      state.contacts.map(
        (contact) => (contact.id == newContact.id ? newContact : contact)
      );
    },

    [MUTATION_REMOVE_CONTACT](state, contactId) {
      state.contacts = state.contacts.filter(
        (contact) => contact.id != contactId
      );
    },

    setIntercomSettingsFormData(state, settings) {
      console.log("intercom form Data", settings);
      state.intercomSettingsFormData = settings;
    },
  },

  actions: {
    /**
     * request method for all contacts
     * */
    getContacts({ dispatch }) {
      return dispatch("executeAction", { method: METHOD_GET_CONTACTS });
    },

    /**
     * request method for sync contacts
     *
     * @param payload contact data
     * */
    syncContacts({ dispatch }, payload) {
      return dispatch("executeAction", {
        method: METHOD_SYNC_CONTACTS,
        data: payload,
      });
    },

    /**
     * request method for adding contact
     *
     * @param payload contact data
     * */
    addContact({ dispatch }, payload) {
      return dispatch("executeAction", {
        method: METHOD_ADD_CONTACT,
        data: payload,
      });
    },

    /**
     * request method for editting contact
     *
     * @param payload contact data
     * */
    editContact({ dispatch }, payload) {
      return dispatch("executeAction", {
        method: METHOD_EDIT_CONTACT,
        data: payload,
      });
    },

    /**
     * request method for removing contact
     *
     * @param payload contact data
     * */
    removeContact({ dispatch }, payload) {
      return dispatch("executeAction", {
        method: METHOD_REMOVE_CONTACT,
        data: payload,
      });
    },

    /**
     * request method for all intercom settings
     * */
    getIntercomSettings({ dispatch }) {
      return dispatch("executeAction", {
        method: METHOD_GET_INTERCOM_SETTINGS,
      });
    },

    /**
     * request method for saving intercom settings
     * */
    saveIntercomSettings({ dispatch }, payload) {
      return dispatch("executeAction", {
        method: METHOD_SAVE_INTERCOM_SETTINGS,
        data: payload,
      });
    },

    /**
     * response method for {@link getContacts}
     *
     * @param event data from socket
     * */
    onReceivedContacts({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_SET_CONTACTS,
      });
    },

    /**
     * response method for {@link syncContacts}
     *
     * @param event data from socket
     * */
    onSyncContacts({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_SET_CONTACTS,
      });
    },

    /**
     * response method for {@link addContact}
     *
     * @param event data from socket
     * */
    onAddContact({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_ADD_CONTACT,
      });
    },

    /**
     * response method for {@link editContact}
     *
     * @param event data from socket
     * */
    onEditContact({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_EDIT_CONTACT,
      });
    },

    /**
     * response method for {@link removeContact}
     *
     * @param event data from socket
     * */
    onRemoveContact({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_REMOVE_CONTACT,
      });
    },

    /**
     * response method for {@link getIntercomSettings}
     *
     * @param event data from socket
     * */
    onReceivedIntercomSettings({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_SET_INTERCOM_SETTINGS,
      });
    },

    /**
     * response method for {@link saveIntercomSettings}
     *
     * @param event data from socket
     * */
    onSaveIntercomSettings({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_SET_INTERCOM_SETTINGS,
      });
    },
  },
};

const intercomAPI = {
  state: {
    intercomSettingsFormData: {},

    intercomSettings: {
      displayName: "",
      enableAutomaticContactSynchronization: false,
      hideMe: false,
      sipServer: {
        enabled: false,
        serverUrl: "",
        port: 0,
        username: "",
        password: "",
        transportType: 0,
      },
    },

    contacts: [],
  },

  getters: {
    intercomSettings: (state) => state.intercomSettings,
    contacts: (state) =>
      state.contacts.filter((contact) => contact.contactType != "multicast"),
    contactCount: (state) => state.contacts.length,
    intercomSettingsFormData: (state) => state.intercomSettingsFormData,
  },

  mutations: {
    [MUTATION_SET_INTERCOM_SETTINGS](state, settings) {
      state.intercomSettings = settings;
    },

    [MUTATION_SET_CONTACTS](state, contacts) {
      state.contacts = contacts;
    },

    [MUTATION_ADD_CONTACT](state, contact) {
      state.contacts.push(contact);
    },

    [MUTATION_EDIT_CONTACT](state, newContact) {
      console.log("mutation edit contact : " + newContact.favorite);
      state.contacts.map(
        (contact) => (contact.id == newContact.id ? newContact : contact)
      );
    },

    [MUTATION_REMOVE_CONTACT](state, contactId) {
      state.contacts = state.contacts.filter(
        (contact) => contact.id != contactId
      );
    },

    setIntercomSettingsFormData(state, settings) {
      console.log("intercom form Data", settings);
      state.intercomSettingsFormData = settings;
    },
  },

  actions: {
    /**
     * request method for all contacts
     * */
    getContacts({ dispatch }) {
      return dispatch('runAction', { action: getContacts, mutation: MUTATION_SET_CONTACTS })
    },

    /**
     * request method for sync contacts
     *
     * @param payload contact data
     * */
    syncContacts({ dispatch }, payload) {
      return dispatch('runAction', { action: syncContacts, data: payload, mutation: MUTATION_SET_CONTACTS })
    },

    /**
     * request method for adding contact
     *
     * @param payload contact data
     * */
    addContact({ dispatch }, payload) {
      return dispatch('runAction', { action: addContact, data: payload, mutation: MUTATION_ADD_CONTACT })  
    },

    /**
     * request method for editting contact
     *
     * @param payload contact data
     * */
    editContact({ dispatch }, payload) {
      return dispatch('runAction', { action: editContact, data: payload, mutation: MUTATION_EDIT_CONTACT })
    },

    /**
     * request method for removing contact
     *
     * @param payload contact data
     * */
    removeContact({ dispatch }, payload) {
      return dispatch('runAction', { action: removeContact, data: payload, mutation: MUTATION_REMOVE_CONTACT })
    },

    /**
     * request method for all intercom settings
     * */
    getIntercomSettings({ dispatch }) {
      return dispatch('runAction', { action: getIntercomSettings, mutation: MUTATION_SET_INTERCOM_SETTINGS })
    },

    /**
     * request method for saving intercom settings
     * */
    saveIntercomSettings({ dispatch }, payload) {
      return dispatch('runAction', { action: editIntercomSettings, data: payload, mutation: MUTATION_SET_INTERCOM_SETTINGS })
    }
  }
};

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? intercomModule : intercomAPI;
