/* Socket Module Mutation Types */
export const MUTATION_SOCKET_ONOPEN = "SOCKET_ONOPEN";
export const MUTATION_SOCKET_ONCLOSE = "SOCKET_ONCLOSE";
export const MUTATION_SOCKET_ONERROR = "SOCKET_ONERROR";
export const MUTATION_SOCKET_ONMESSAGE = "SOCKET_ONMESSAGE";
export const MUTATION_SOCKET_RECONNECT = "SOCKET_RECONNECT";
export const MUTATION_SOCKET_RECONNECT_ERROR = "SOCKET_RECONNECT_ERROR";

/* Auth Module Mutation Types */
export const MUTATION_SET_TOKEN = "setToken";
export const MUTATION_SET_USERID = "setUserId";
export const MUTATION_SET_USERNAME = "setUsername";
export const MUTATION_SET_USERROLE = "setUserRole";
export const MUTATION_SET_EXPIRE_DATE = "setExpireDate";
export const MUTATION_EDIT_PASSWORD = "editPassword";
export const MUTATION_SET_PRODUCT_CODE = "setProductCode";
export const MUTATION_SET_RS232 = "setRS232";
export const MUTATION_SET_RS485 = "setRS485";
export const MUTATION_SET_RELAY_TYPE = "setRelayType";
export const MUTATION_SET_BRAND = "setBrand";
export const MUTATION_SET_COREOS_VERSION = "setCoreOSVersion";
export const MUTATION_SET_SERIAL_NUMBER = "setSerialNumber";
export const MUTATION_SET_CONNECTED_DEVICE_ID = "setConnectedDeviceId";
export const MUTATION_SET_TECHNICAL_ACCESS_CODE = "setTechnicalAccessCode";
export const MUTATION_SET_ROLES = "setRoles";

/* Driver Module Mutation Types */
export const MUTATION_SET_DRIVERS = "setDrivers";
export const MUTATION_ADD_DRIVER = "addDriver";
export const MUTATION_EDIT_DRIVER = "editDriver";
export const MUTATION_REMOVE_DRIVER = "removeDriver";
export const MUTATION_REFRESH_DRIVER = "refreshDriver";

/* Accessory Module Mutation Types */
export const MUTATION_SET_ACCESSORIES = "setAccessories";
export const MUTATION_ADD_ACCESSORY = "addAccessory";
export const MUTATION_EDIT_ACCESSORY = "editAccessory";
export const MUTATION_REMOVE_ACCESSORY = "removeAccessory";

/* IP Camera Module Mutation Types */
export const MUTATION_SET_IPCAMERAS = "setIPCameras";
export const MUTATION_ADD_IPCAMERA = "addIPCamera";
export const MUTATION_EDIT_IPCAMERA = "editIPCamera";
export const MUTATION_REMOVE_IPCAMERA = "removeIPCamera";

/* Room Module Mutation Types */
export const MUTATION_SET_ROOMS = "setRooms";
export const MUTATION_ADD_ROOM = "addRoom";
export const MUTATION_EDIT_ROOM = "editRoom";
export const MUTATION_REMOVE_ROOM = "removeRoom";

/* Section Module Mutation Types */
export const MUTATION_SET_SECTIONS = "setSections";
export const MUTATION_ADD_SECTION = "addSection";
export const MUTATION_EDIT_SECTION = "editSection";
export const MUTATION_REMOVE_SECTION = "removeSection";

/* Scene Module Mutation Types */
export const MUTATION_SET_SCENES = "setScenes";
export const MUTATION_ADD_SCENE = "addScene";
export const MUTATION_EDIT_SCENE = "editScene";
export const MUTATION_REMOVE_SCENE = "removeScene";

/* Automation Module Mutation Types */
export const MUTATION_SET_TRIGGERS = "setTriggers";
export const MUTATION_ADD_TRIGGER = "addTrigger";
export const MUTATION_EDIT_TRIGGER = "editTrigger";
export const MUTATION_REMOVE_TRIGGER = "removeTrigger";

/* General Module Mutation Types */
export const MUTATION_SET_GENERAL_SETTINGS = "setGeneralSettings";
export const MUTATION_SET_GENERAL_INFO = "setGeneralInfo";

/* Room Module Mutation Types */
export const MUTATION_SET_CONTACTS = "setContacts";
export const MUTATION_ADD_CONTACT = "addContact";
export const MUTATION_EDIT_CONTACT = "editContact";
export const MUTATION_REMOVE_CONTACT = "removeContact";
export const MUTATION_SET_INTERCOM_SETTINGS = "setIntercomSettings";

/* Widget Module Mutation Types */
export const MUTATION_SET_WIDGETS = "setWidgets";
export const MUTATION_EDIT_WIDGETS = "editWidgets";

/* Menu Items Module Mutation Types*/
export const MUTATION_SET_MENU_ITEMS = "setMenuItems";
export const MUTATION_EDIT_MENU_ITEMS = "editMenuItems";

/* Command Module Mutation Types */
export const MUTATION_ENABLE_ADB = "enableADB";
export const MUTATION_ENABLE_OTG = "enableOTG";
export const MUTATION_PLAY_SOUND = "playSound";
export const MUTATION_RUN_SIP_ACTION = "executeSipAction";
export const MUTATION_REBOOT_DEVICE = "rebootDevice";

/* Network Module Mutation Types */
export const MUTATION_SET_NETWORK_SETTINGS = "setNetworkSettings";

/* Security Module Mutation Types */
export const MUTATION_SET_SECURITY_SETTINGS = "setSecuritySettings";

/* Log Module Mutation Types */
export const MUTATION_SET_LOGS = "setLogs";
export const MUTATION_SET_MESSAGES = "setMessages";

/* Sensors Module Mutation Types */
export const MUTATION_SET_ARMING_SETTINGS = "setArmingSettings";
export const MUTATION_SET_SECURITY_PANEL_SETTINGS = "setSecurityPanelSettings";

export const MUTATION_EDIT_RSS_SETTINGS = "editRssSettings";
