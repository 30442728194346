import {
    MUTATION_EDIT_RSS_SETTINGS
  } from "../mutation-types";
  
import {
    METHOD_GET_RSS_SETTINGS,
    METHOD_EDIT_RSS_SETTINGS
  } from "../method-names";

import { 
    getRssSettings, 
    editRssSettings 
} from "@/api/rss";

  const rssModule = {
      state:{
          rssSettings:{}
      },
      getters: {
        rssSettings: state => state.rssSettings
      },
      mutations: {
        [MUTATION_EDIT_RSS_SETTINGS] (state, rssSettings) {
          state.rssSettings = rssSettings
        }
      },     
      actions: {
        getRssSettings({dispatch}) {
            return dispatch('executeAction', { method: METHOD_GET_RSS_SETTINGS })
        },
        editRssSettings({dispatch}, payload) {
            return dispatch('executeAction', { method: METHOD_EDIT_RSS_SETTINGS, data: payload })
          },      
          
          onEditRssSettings ({dispatch}, event) {
            return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_RSS_SETTINGS })
          },

          onReceivedRssSettings ({dispatch}, event) {
            return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_RSS_SETTINGS })
          }          
      } 
  }

  const rssAPI = {
    state:{
        rssSettings:{}
    },
    getters: {
      rssSettings: state => state.rssSettings
    },
    mutations: {
      [MUTATION_EDIT_RSS_SETTINGS] (state, rssSettings) {
        state.rssSettings = rssSettings
      }
    },     
    actions: {
      getRssSettings({dispatch}) {
        return dispatch('runAction', { action: getRssSettings, mutation: MUTATION_EDIT_RSS_SETTINGS })
      },
      editRssSettings({dispatch}, payload) {
        return dispatch('runAction', { action: editRssSettings, data: payload, mutation: MUTATION_EDIT_RSS_SETTINGS })
      }
    } 
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? rssModule : rssAPI;