import Vue from "vue";
import router from "../../router/router";
import { EXPIRE_TIME } from "../constants";
import {
  MUTATION_EDIT_PASSWORD,
  MUTATION_ENABLE_ADB,
  MUTATION_SET_EXPIRE_DATE,
  MUTATION_SET_TOKEN,
  MUTATION_SET_USERID,
  MUTATION_SET_USERNAME,
  MUTATION_SET_USERROLE,
  MUTATION_SET_PRODUCT_CODE,
  MUTATION_SET_RS232,
  MUTATION_SET_RS485,
  MUTATION_SET_RELAY_TYPE,
  MUTATION_SET_BRAND,
  MUTATION_SET_SERIAL_NUMBER,
  MUTATION_SET_COREOS_VERSION,
  MUTATION_SET_TECHNICAL_ACCESS_CODE,
  MUTATION_SET_CONNECTED_DEVICE_ID,
  MUTATION_SET_ROLES,
} from "../mutation-types";
import {
  METHOD_EDIT_PASSWORD,
  METHOD_ENABLE_ADB,
  METHOD_LOGIN,
} from "../method-names";
import { getUserId, setUserId, removeUserId, 
  getToken, setToken, removeToken, setExpireDate, getExpireDate, 
  getDeviceId, setDeviceId, removeDeviceId, 
  getTechnicalAccessCode, setTechnicalAccessCode, removeTechnicalAccessCode,
  getRoles, setRoles } from "@/api/auth";
import { login, checkAuth, getInfo, changePassword, getRoles as getRolesAPI } from "@/api/user";


const authModule = {
  state: {
    username: "",
    userRole: 2, //2: Web Admin, 3: Super Admin
    token: "",
    expireDate: 0,
    version: "1",
    productCode: "",
    brand: "",
    relayType: 1,
    existsRS232: true,
    existsRS485: true,
    serialNumber: "",
    coreosVersion: "",
  },

  //state, getters, rootState)
  getters: {
    isSignedIn(state) {
      return state.token != undefined;
    },
    username: (state) => {
      return state.username;
    },
    isSidebarVisible(state) {
      return true;
    },
    isLocalMode(state) {
      return process.env.VUE_APP_REMOTE_MODE == 'false'
    },
    userRole: (state) => state.userRole,
    productCode: (state) => state.productCode,
    relayType: (state) => state.relayType,
    existsRS232: (state) => state.existsRS232,
    existsRS485: (state) => state.existsRS485,
    brand: (state) => state.brand,
    serialNumber: (state) => state.serialNumber,
    coreosVersion: (state) => state.coreosVersion,
  },

  mutations: {
    [MUTATION_SET_USERNAME](state, username) {
      state.username = username;
      localStorage.setItem("username", username);
    },

    [MUTATION_SET_USERROLE](state, userRole) {
      state.userRole = userRole;
      localStorage.setItem("userRole", userRole);
    },

    [MUTATION_SET_TOKEN](state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },

    [MUTATION_SET_EXPIRE_DATE](state, expireDate) {
      state.expireDate = expireDate;
    },

    [MUTATION_EDIT_PASSWORD](state, data) {},

    [MUTATION_SET_PRODUCT_CODE](state, productCode) {
      state.productCode = productCode;
      localStorage.setItem("productCode", productCode);
    },

    [MUTATION_SET_BRAND](state, brand) {
      state.brand = brand;
      localStorage.setItem("brand", brand);
    },

    [MUTATION_SET_RELAY_TYPE](state, relayType) {
      state.relayType = relayType;
      localStorage.setItem("relayType", relayType);
    },

    [MUTATION_SET_RS232](state, RS232) {
      state.existsRS232 = RS232;
      localStorage.setItem("RS232", RS232);
    },

    [MUTATION_SET_RS485](state, RS485) {
      state.existsRS485 = RS485;
      localStorage.setItem("RS485", RS485);
    },

    [MUTATION_SET_COREOS_VERSION](state, coreOSVersion) {
      state.coreosVersion = coreOSVersion;
      localStorage.setItem("coreosVersion", coreOSVersion);
    },

    [MUTATION_SET_SERIAL_NUMBER](state, serialNumber) {
      state.serialNumber = serialNumber;
      localStorage.setItem("serialNumber", serialNumber);
    },
  },

  actions: {
    initAuth({ commit }) {
      if (localStorage.getItem("token")) {
        commit(MUTATION_SET_TOKEN, localStorage.getItem("token"));
        commit(MUTATION_SET_USERNAME, localStorage.getItem("username"));
        commit(MUTATION_SET_USERROLE, localStorage.getItem("userRole"));

        commit(MUTATION_SET_PRODUCT_CODE, localStorage.getItem("productCode"));
        commit(MUTATION_SET_BRAND, localStorage.getItem("brand"));
        commit(
          MUTATION_SET_SERIAL_NUMBER,
          localStorage.getItem("serialNumber")
        );
        commit(
          MUTATION_SET_COREOS_VERSION,
          localStorage.getItem("coreosVersion")
        );

        commit(MUTATION_SET_RELAY_TYPE, localStorage.getItem("relayType"));
        commit(MUTATION_SET_RS232, localStorage.getItem("RS232"));
        commit(MUTATION_SET_RS485, localStorage.getItem("RS485"));
        //router.push({name: "Home"})
      } else {
        router.push({ name: "Login" });
      }
    },

    signIn({ rootState, state, commit }, payload) {
      return new Promise((resolve, reject) => {
        rootState.promises[METHOD_LOGIN] = { resolve, reject };

        Vue.prototype.$socket.sendObj({
          method: METHOD_LOGIN,
          data: {
            version: state.version,
            ...payload,
          },
        });
      });
    },

    /**
     * request method to change web user password
     *
     * @param payload new Password
     * */
    changePassword({ dispatch }, payload) {
      return dispatch("executeAction", {
        method: METHOD_EDIT_PASSWORD,
        data: payload,
      });
    },

    logout({ commit }) {
      commit(MUTATION_SET_TOKEN, "");
    },

    onLogin({ rootState, state, commit }, event) {
      console.log(event);

      if (event.error) {
        commit(MUTATION_SET_TOKEN, "");
        commit(MUTATION_SET_USERNAME, "");
        commit(MUTATION_SET_USERROLE, 2);
        commit(MUTATION_SET_EXPIRE_DATE, 0);
        commit(MUTATION_SET_PRODUCT_CODE, "");
        commit(MUTATION_SET_BRAND, "");
        commit(MUTATION_SET_RELAY_TYPE, 1);
        commit(MUTATION_SET_RS232, true);
        commit(MUTATION_SET_RS485, true);
        commit(MUTATION_SET_SERIAL_NUMBER, "");
        commit(MUTATION_SET_COREOS_VERSION, "");

        rootState.promises[event.method].reject(event.error.description);
      } else {
        const data = event.response.data;

        commit(MUTATION_SET_TOKEN, data.token);
        commit(MUTATION_SET_USERNAME, data.user);
        commit(MUTATION_SET_USERROLE, data.userRole);
        commit(MUTATION_SET_EXPIRE_DATE, data.expireDate);

        commit(MUTATION_SET_PRODUCT_CODE, data.productCode);
        commit(MUTATION_SET_BRAND, data.brand);
        commit(MUTATION_SET_RELAY_TYPE, data.relayType);
        commit(MUTATION_SET_RS232, data.RS232);
        commit(MUTATION_SET_RS485, data.RS485);
        commit(MUTATION_SET_SERIAL_NUMBER, data.serialNumber);
        commit(MUTATION_SET_COREOS_VERSION, data.coreosVersion);

        rootState.promises[event.method].resolve(event.response.message);
      }

      delete rootState.promises[event.method];
    },

    /**
     * response method for {@link changePassword}
     *
     * @param event data from socket
     * */
    onEditPassword({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_EDIT_PASSWORD,
      });
    },
  },
};

const authAPI = {
  state: {
    connectedDeviceId: getDeviceId(),
    technicalAccessCode: getTechnicalAccessCode(),
    username: "",
    userRole: 2, //2: Web Admin, 3: Super Admin
    token: getToken(),
    userId: getUserId(),
    expireDate: getExpireDate(),
    version: "1",
    roles: getRoles(),
  },

  //state, getters, rootState)
  getters: {
    connectedDeviceId: (state) => state.connectedDeviceId,
    technicalAccessCode: (state) => state.technicalAccessCode,
    isConnectedDevice(state) {
      return state.technicalAccessCode
    },
    isSignedIn(state) {
      return !!state.token
    },
    expireDate: (state) => state.expireDate,
    username: (state) => state.username,
    userRole: (state) => state.userRole,

    userId: (state) => state.userId,
    token: (state) => state.token,

    roles: (state) => state.roles,
  },

  mutations: {
    [MUTATION_SET_USERNAME](state, username) {
      state.username = username;
      localStorage.setItem("username", username);
    },

    [MUTATION_SET_USERROLE](state, userRole) {
      state.userRole = userRole;
      localStorage.setItem("userRole", userRole);
    },

    [MUTATION_SET_CONNECTED_DEVICE_ID](state, deviceId) {
      state.connectedDeviceId = deviceId
      setDeviceId(deviceId)
    },

    [MUTATION_SET_TECHNICAL_ACCESS_CODE](state, accessCode) {
      state.technicalAccessCode = accessCode
      setTechnicalAccessCode(accessCode)
    },

    [MUTATION_SET_TOKEN](state, token) {
      state.token = token;
      setToken(token)
    },

    [MUTATION_SET_USERID](state, userId) {
      state.userId = userId;
      setUserId(userId)
    },

    [MUTATION_SET_EXPIRE_DATE](state, expireDate) {
      state.expireDate = expireDate;
      setExpireDate(expireDate)
    },

    [MUTATION_EDIT_PASSWORD](state, data) {},

    [MUTATION_SET_ROLES](state, roles) {
      state.roles = roles;
      setRoles(roles)
    },

    // [MUTATION_SET_PRODUCT_CODE](state, productCode) {
    //   state.productCode = productCode;
    //   localStorage.setItem("productCode", productCode);
    // },

    // [MUTATION_SET_BRAND](state, brand) {
    //   state.brand = brand;
    //   localStorage.setItem("brand", brand);
    // },

    // [MUTATION_SET_RELAY_TYPE](state, relayType) {
    //   state.relayType = relayType;
    //   localStorage.setItem("relayType", relayType);
    // },

    // [MUTATION_SET_RS232](state, RS232) {
    //   state.existsRS232 = RS232;
    //   localStorage.setItem("RS232", RS232);
    // },

    // [MUTATION_SET_RS485](state, RS485) {
    //   state.existsRS485 = RS485;
    //   localStorage.setItem("RS485", RS485);
    // },

    // [MUTATION_SET_COREOS_VERSION](state, coreOSVersion) {
    //   state.coreosVersion = coreOSVersion;
    //   localStorage.setItem("coreosVersion", coreOSVersion);
    // },

    // [MUTATION_SET_SERIAL_NUMBER](state, serialNumber) {
    //   state.serialNumber = serialNumber;
    //   localStorage.setItem("serialNumber", serialNumber);
    // },
  },

  actions: {
    initAuth({ commit }) {
      if (getToken()) {
        commit(MUTATION_SET_TOKEN, getToken());
        commit(MUTATION_SET_USERID, getUserId());
        //commit(MUTATION_SET_CONNECTED_DEVICE_ID, getDeviceId());
        //commit(MUTATION_SET_TECHNICAL_ACCESS_CODE, getTechnicalAccessCode());

        commit(MUTATION_SET_USERNAME, localStorage.getItem("username"));
        commit(MUTATION_SET_USERROLE, localStorage.getItem("userRole"));






        // commit(MUTATION_SET_PRODUCT_CODE, localStorage.getItem("productCode"));
        // commit(MUTATION_SET_BRAND, localStorage.getItem("brand"));
        // commit(
        //   MUTATION_SET_SERIAL_NUMBER,
        //   localStorage.getItem("serialNumber")
        // );
        // commit(
        //   MUTATION_SET_COREOS_VERSION,
        //   localStorage.getItem("coreosVersion")
        // );

        // commit(MUTATION_SET_RELAY_TYPE, localStorage.getItem("relayType"));
        // commit(MUTATION_SET_RS232, localStorage.getItem("RS232"));
        // commit(MUTATION_SET_RS485, localStorage.getItem("RS485"));
        //router.push({name: "Home"})
      } else {
        router.push({ name: "Login" });
      }
    },

    signIn({ rootState, state, commit }, payload) {
      return new Promise((resolve, reject) => {
        checkAuth(payload)
        .then(res => {
          console.log(res)
          if (res.code === 403 || res.status === "error") {
            return reject("socket.auth.user-not-auth")
          }
          
          login(payload)
            .then( async response => {
            
            const { data } = response
            
            console.log("SignIn Success", data)
            commit(MUTATION_SET_TOKEN, data.authToken);
            commit(MUTATION_SET_USERID, data.userId);
            commit(MUTATION_SET_EXPIRE_DATE, Date.now() + EXPIRE_TIME ); 

            const info = await getInfo()
            if ( info.data ) {
              commit(MUTATION_SET_USERNAME, info.data.displayName || info.data.username);
              commit(MUTATION_SET_ROLES, info.data.roles || {});
            }
            console.log("Profile Info", info.data)

            resolve("socket.auth.login-successful")
          })
          .catch(error => {
            console.log("SignIn Fail", error)
            commit(MUTATION_SET_TOKEN, "");
            commit(MUTATION_SET_USERID, "");
            commit(MUTATION_SET_ROLES, "");
            reject("socket.auth.user-not-found")
          })
        })
        .catch(error => {
          console.log("SignIn Fail", error)
          commit(MUTATION_SET_TOKEN, "");
          commit(MUTATION_SET_USERID, "");
          commit(MUTATION_SET_ROLES, "");
          reject("socket.auth.user-not-auth")
        })
      });
    },

    connectToDevice({ commit }, payload) {
      return new Promise((resolve, reject) => {
        console.log("Connect To Device", payload)
          commit(MUTATION_SET_CONNECTED_DEVICE_ID, payload.deviceId);
          commit(MUTATION_SET_TECHNICAL_ACCESS_CODE, payload.accessCode);
          resolve()
      });
    },

    /**
     * request method to change web user password
     *
     * @param payload new Password
     * */
    changePassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        changePassword(payload)
        .then(response => {
            console.log("change Password Success", response)
            commit(MUTATION_SET_TOKEN, "");
            commit(MUTATION_SET_USERID, "");
            resolve({ message: "socket.auth.password-edited" })
        })
        .catch(error => {
          console.log("change Password Fail", error)
          reject("socket.auth.unable-edit-password")
        })
      })
    },

    logout({ commit }) {
      commit(MUTATION_SET_TOKEN, "");
      commit(MUTATION_SET_USERID, "");
      commit(MUTATION_SET_CONNECTED_DEVICE_ID, "");
      commit(MUTATION_SET_TECHNICAL_ACCESS_CODE, "");
      commit(MUTATION_SET_ROLES, "");
    },

    onLogin({ rootState, state, commit }, event) {
      if (event.error) {
        commit(MUTATION_SET_TOKEN, "");
        commit(MUTATION_SET_USERNAME, "");
        commit(MUTATION_SET_USERROLE, 2);
        commit(MUTATION_SET_EXPIRE_DATE, 0);
        commit(MUTATION_SET_PRODUCT_CODE, "");
        commit(MUTATION_SET_BRAND, "");
        commit(MUTATION_SET_RELAY_TYPE, 1);
        commit(MUTATION_SET_RS232, true);
        commit(MUTATION_SET_RS485, true);
        commit(MUTATION_SET_SERIAL_NUMBER, "");
        commit(MUTATION_SET_COREOS_VERSION, "");
        commit(MUTATION_SET_ROLES, "");

        rootState.promises[event.method].reject(event.error.description);
      } else {
        const data = event.response.data;

        commit(MUTATION_SET_TOKEN, data.token);
        commit(MUTATION_SET_USERNAME, data.user);
        commit(MUTATION_SET_USERROLE, data.userRole);
        commit(MUTATION_SET_EXPIRE_DATE, data.expireDate);

        commit(MUTATION_SET_PRODUCT_CODE, data.productCode);
        commit(MUTATION_SET_BRAND, data.brand);
        commit(MUTATION_SET_RELAY_TYPE, data.relayType);
        commit(MUTATION_SET_RS232, data.RS232);
        commit(MUTATION_SET_RS485, data.RS485);
        commit(MUTATION_SET_SERIAL_NUMBER, data.serialNumber);
        commit(MUTATION_SET_COREOS_VERSION, data.coreosVersion);

        rootState.promises[event.method].resolve(event.response.message);
      }

      delete rootState.promises[event.method];
    },

    /**
     * response method for {@link changePassword}
     *
     * @param event data from socket
     * */
    onEditPassword({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_EDIT_PASSWORD,
      });
    },
  },
};

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? authModule : authAPI;

// export default authModule;
