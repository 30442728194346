<template>
  <el-aside :width="$store.getters.isMenuCollapsed ? 64 + 'px' : 280 + 'px'">
    <el-row v-show="!$store.getters.isMenuCollapsed">
      <el-col class="logo">
        <h3 style="color: #ffd04b;">{{ brandTitle }}</h3>
      </el-col>
      <el-col>
        <el-menu
          unique-opened
          background-color="#fcfcfc"
          text-color="#000"
          :default-active="$route.path"
          router
        >
          <template v-for="(item, index) in $router.options.routes">
            <el-submenu
              :index="index + ''"
              v-if="
                !item.hidden &&
                  !item.leaf &&
                  showAdvancedMenu(item.advanced) &&
                  showByBrand(item.brand)
              "
              :key="`route-${index}-${item.path}`"
            >
              <template class="menu-item" slot="title">
                <div class="menu-item">
                  <!-- <i :class="item.meta.icon"></i> -->
                  <img class="sidebar-icon" :src=getImgPath(item.meta.icon)>
                  <span>{{ getTitle(item.meta.title) }}</span>
                </div>
                </template>

              <template v-for="child in item.children">
                <el-menu-item
                  :key="child.path"
                  v-if="
                    !child.hidden &&
                      showAdvancedMenu(child.advanced) &&
                      showByBrand(child.brand)
                  "
                  :route="child"
                  :index="child.path"
                  @click="handleMenuClick"
                >
                <div class="menu-item">
                  <!-- <i :class="child.meta.icon"></i> -->
                  <img class="sidebar-icon" :src=getImgPath(child.meta.icon)>
                  <span>{{ getTitle(child.meta.title) }}</span>
                </div>
                  
                </el-menu-item>

                <template
                  v-else-if="
                    child.hidden && child.children && child.children.length > 0
                  "
                >
                  <el-menu-item
                    v-for="grandchild in child.children"
                    :index="grandchild.path"
                    :key="grandchild.path"
                    v-if="!grandchild.hidden"
                    :route="grandchild"
                    @click="handleMenuClick"
                  >
                  <div class="menu-item">
                    <!-- <i :class="grandchild.meta.icon"></i> -->
                    <img class="sidebar-icon" :src=getImgPath(grandchild.meta.icon)>
                    <span>{{ getTitle(grandchild.meta.title) }}</span>
                </div>  
                  </el-menu-item>
                </template>
              </template>
            </el-submenu>

            <el-menu-item
              v-if="item.leaf && item.children.length > 0"
              :index="item.children[0].path"
              :key="item.children[0].path"
              :route="item"
              @click="handleMenuClick"
            >
            <div class="menu-item">
              <!-- <el-image class="test" 
                style="width: 40px; height: 40px"
                :src="getPic('detail')">
              </el-image> -->
              <!-- <p>{{ url }}</p> -->
              <!-- <img src="../../../../assets/icons/detail.png" v-bind:alt="pic"> -->
              <!-- <i :class="item.children[0].meta.icon"></i> -->
              <!-- <img class="test" :src=imgPath> -->
              <img class="sidebar-icon" :src=getImgPath(item.children[0].meta.icon)>
              <span>{{ getTitle(item.children[0].meta.title) }}</span>
            </div>  
            </el-menu-item>
          </template>
        </el-menu>
      </el-col>
      <el-col>
      <small class="copyright"
          >2020 <i class="far fa-copyright"></i> {{ copyright }} - v4.0.10</small>
        </el-col>
    </el-row>




    <PopupMenu></PopupMenu>
  </el-aside>
</template>

<script>
import PopupMenu from "./PopupMenu";

export default {
  name: "index",

  components: {
    PopupMenu,
  },

  data() {
    return {
      imgs:['detail.png'],
      img: 'detail.png',
      stats: [
        { title: this.$t('dashboard.drivers'), icon: "fa fa-hdd", value: 0, mouseOver: false, loading: false, onload: () => this.stats[0].value = this.driverCount, method: "getDrivers", router: "Drivers" },
        { title: this.$t('dashboard.accessories'), icon: "fa fa-power-off", value: 0, mouseOver: false, loading: false, onload: () => this.stats[1].value = this.accessoryCount, method: "getAccessories", router: "Accessories" },
        { title: this.$t('dashboard.rooms'), icon: "fa fa-home", value: 0, mouseOver: false, loading: false, onload: () => this.stats[2].value = this.roomCount, method: "getRooms", router: "Rooms" },
        { title: this.$t('dashboard.contacts'), icon: "fa fa-user", value: 0, mouseOver: false, loading: false, onload: () => this.stats[3].value = this.contactCount, method: "getContacts", router: "Contacts" },
        { title: this.$t('dashboard.scenes'), icon: "fa fa-play-circle", value: 0, mouseOver: false, loading: false, onload: () => this.stats[4].value = this.sceneCount, method: "getScenes", router: "Scenes" },
        { title: this.$t('dashboard.cameras'), icon: "fa fa-video", value: 0, mouseOver: false, loading: false, onload: () => this.stats[5].value = this.ipcameraCount, method: "getIPCameras", router: "IPCameras" },
      ],
    }
  },

  computed: {
    brandTitle: function() {
      return this.$store.getters.brand === "EAE"
        ? "EAE Technology"
        : "CORE SMART HOME";
    },
    copyright: function() {
      return this.$store.getters.brand === "EAE" ? "EAE" : "Core";
    },
    imgPath() {
            return require(`../../../../assets/icons/${this.img}`);
    }
  },

  methods: {
    handleMenuClick(menu) {
      console.log(menu.index);
      console.log(menu.route);
    },
    getTitle(key) {
      const hasKey = this.$te("router." + key);

      if (hasKey) {
        return this.$t("router." + key);
      }

      return key;
    },

    showAdvancedMenu: function(advanced) {
      if (advanced) return this.$store.getters.userRole == 3;
      return true;
    },

    showByBrand: function(brand) {
      if (brand) return brand == this.$store.getters.touchPanelBrand;
      return true;
    },
    getImgPath: function(icon) {
        return require(`../../../../assets/icons/` + icon + `.png`);
    }
  },
};
</script>

<style scoped>
.sidebar-icon{
  filter: invert(52%) sepia(98%) saturate(1506%) hue-rotate(146deg) brightness(97%) contrast(101%);
  height: 32px;
  width: 32px;
  margin-right: 10px;
}
.el-aside {
  background-color: #fcfcfc;
  z-index: 9999;
  box-shadow: 0px 0px 25px #333;
  overflow: auto;
  float: left;
}
.logo {
  padding: 20px;
  margin-top: 25px;
}
.el-menu {
  border-width: 0px;
  margin-bottom: 24px;

}

.el-menu i {
  color: #00BFD7;
  padding: 5px 10px 5px 0px;
}

.el-menu-item {
  font-size: 16px;
  font-family:"SF Medium";
}

.menu-item {
  font-size: 16px;
  font-family:"SF Medium";
}

h3 {
  color: #ccc;
}

.copyright {
  position: fixed;
  bottom: 10px;
  margin: auto;
  text-align: center;
  color: #999;
  width: 280px;
  font-size: 14px;
  font-family:"SF Light";
}
</style>
