var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.getters.isMenuCollapsed,
          expression: "$store.getters.isMenuCollapsed"
        }
      ],
      staticClass: "el-menu collapsed"
    },
    [
      _vm._l(_vm.$router.options.routes, function(item, index) {
        return [
          !item.hidden && !item.leaf
            ? _c(
                "li",
                {
                  key: index,
                  staticClass: "el-submenu",
                  staticStyle: { position: "relative" }
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        width: "180",
                        trigger: "hover"
                      }
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "el-menu submenu" },
                        [
                          _vm._l(item.children, function(child) {
                            return [
                              !child.hidden
                                ? _c(
                                    "li",
                                    {
                                      key: child.path,
                                      staticClass: "item",
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push({
                                            name: child.name
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { class: child.meta.icon }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getTitle(child.meta.title)
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              child.hidden &&
                              child.children &&
                              child.children.length > 0
                                ? _vm._l(child.children, function(grandchild) {
                                    return !grandchild.hidden
                                      ? _c(
                                          "li",
                                          {
                                            key: child.path,
                                            staticClass: "item",
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  name: grandchild.name
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              class: grandchild.meta.icon
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getTitle(
                                                    grandchild.meta.title
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  })
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-submenu__title",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        },
                        [
                          _c("i", {
                            staticClass: "menu-icon",
                            class: item.meta.icon
                          })
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          item.leaf && item.children.length > 0
            ? _c("li", { key: item.children[0].path }, [
                _c(
                  "div",
                  {
                    staticClass: "el-submenu__title",
                    on: {
                      click: function($event) {
                        return _vm.$router.push({ name: item.children[0].name })
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "menu-icon",
                      class: item.children[0].meta.icon
                    })
                  ]
                )
              ])
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }