import request from '@/api/relay-service'
import { METHOD_GET_LOGS } from '../store/method-names'

export function getLogs(){
    return request({
        params: {
            action: METHOD_GET_LOGS
        },
        method: 'get'
    })
}