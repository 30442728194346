const KEY_DEVICE_INFO = "core-dp-device-info"

export function getDeviceInfo(){
    const info = localStorage.getItem(KEY_DEVICE_INFO)
    if (info) {
        return JSON.parse(info)
    } else {
        return {}
    }
}

export function setDeviceInfo(info) {
    localStorage.setItem(KEY_DEVICE_INFO, JSON.stringify(info))
}

export function getProductCode(){
    return getDeviceInfo()["productCode"]
}

export function getBrand(){
    return getDeviceInfo()["brand"]
}

export function getSerialNumber(){
    return getDeviceInfo()["serialNumber"]
}

export function getCoreOSVersion(){
    return getDeviceInfo()["version"]
}

export function existsRS232(){
    return getDeviceInfo()["RS232"]
}

export function existsRS485(){
    return getDeviceInfo()["RS485"]
}

export function getRelayType(){
    return getDeviceInfo()["relayType"]
}

