import request from '@/api/relay-service'
import { METHOD_EDIT_WIDGETS, METHOD_GET_WIDGETS } from '../store/method-names'

export function getWidgets(){
    return request({
        params: {
            action: METHOD_GET_WIDGETS
        },
        method: 'get'
    })
}

export function editWidgets(data){
    return request({
        params: {
            action: METHOD_EDIT_WIDGETS
        },
        method: 'post',
        data
    })
}