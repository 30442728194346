import {
  MUTATION_ADD_SECTION,
  MUTATION_EDIT_SECTION,
  MUTATION_REMOVE_SECTION,
  MUTATION_SET_SECTIONS
} from "../mutation-types";
import {
  METHOD_ADD_SECTION,
  METHOD_EDIT_SECTION,
  METHOD_GET_SECTIONS,
  METHOD_REMOVE_SECTION, METHOD_SYNC_SECTIONS
} from "../method-names";
import { 
  getSections, 
  syncSections, 
  addSection, 
  editSection, 
  removeSection 
} from "@/api/section";

const sectionModule = {
  state: {
    sections: []
  },

  getters: {
    sections: state => state.sections,
    sectionCount: state => state.sections.length
  },

  mutations: {
    [MUTATION_SET_SECTIONS] (state, sections) {
      state.sections = sections
    },

    [MUTATION_ADD_SECTION] (state, section) {
      state.sections.push(section)
    },

    [MUTATION_EDIT_SECTION] (state, newSection) {
      state.sections = state.sections.map(section => {
        return section.id == newSection.id ? newSection : section
      })
    },

    [MUTATION_REMOVE_SECTION] (state, sectionId) {
      state.sections = state.sections.filter(section => section.id != sectionId)
    }
  },

  actions: {
    /**
     * request method for all Sections
     * */
    getSections({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_SECTIONS })
    },

    /**
     * request method for adding Section
     *
     * @param payload Section data
     * */
    syncSections({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SYNC_SECTIONS, data: payload })
    },

    /**
     * request method for adding Section
     *
     * @param payload Section data
     * */
    addSection({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ADD_SECTION, data: payload })
    },

    /**
     * request method for editting Section
     *
     * @param payload Section data
     * */
    editSection({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_EDIT_SECTION, data: payload })
    },

    /**
     * request method for removing Section
     *
     * @param payload Section data
     * */
    removeSection({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_REMOVE_SECTION, data: payload })
    },


    /**
     * response method for {@link getSections}
     *
     * @param event data from socket
     * */
    onReceivedSections ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_SECTIONS })
    },

    /**
     * response method for {@link syncSections}
     *
     * @param event data from socket
     * */
    onSyncSections ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_SECTIONS })
    },

    /**
     * response method for {@link addSection}
     *
     * @param event data from socket
     * */
    onAddSection ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ADD_SECTION })
    },

    /**
     * response method for {@link editSection}
     *
     * @param event data from socket
     * */
    onEditSection ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_SECTION})
    },

    /**
     * response method for {@link removeSection}
     *
     * @param event data from socket
     * */
    onRemoveSection ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REMOVE_SECTION})
    }
  }
}

const sectionAPI = {
  state: {
    sections: []
  },

  getters: {
    sections: state => state.sections,
    sectionCount: state => state.sections.length
  },

  mutations: {
    [MUTATION_SET_SECTIONS] (state, sections) {
      state.sections = sections
    },

    [MUTATION_ADD_SECTION] (state, section) {
      state.sections.push(section)
    },

    [MUTATION_EDIT_SECTION] (state, newSection) {
      state.sections = state.sections.map(section => {
        return section.id == newSection.id ? newSection : section
      })
    },

    [MUTATION_REMOVE_SECTION] (state, sectionId) {
      state.sections = state.sections.filter(section => section.id != sectionId)
    }
  },

  actions: {
    /**
     * request method for all Sections
     * */
    getSections({dispatch}) {
      return dispatch('runAction', { action: getSections, mutation: MUTATION_SET_SECTIONS })
    },

    /**
     * request method for adding Section
     *
     * @param payload Section data
     * */
    syncSections({dispatch}, payload) {
      return dispatch('runAction', { action: syncSections, data: payload, mutation: MUTATION_SET_SECTIONS })
    },

    /**
     * request method for adding Section
     *
     * @param payload Section data
     * */
    addSection({dispatch}, payload) {
      return dispatch('runAction', { action: addSection, data: payload, mutation: MUTATION_ADD_SECTION })
    },

    /**
     * request method for editting Section
     *
     * @param payload Section data
     * */
    editSection({dispatch}, payload) {
      return dispatch('runAction', { action: editSection, data: payload, mutation: MUTATION_EDIT_SECTION })
    },

    /**
     * request method for removing Section
     *
     * @param payload Section data
     * */
    removeSection({dispatch}, payload) {
      return dispatch('runAction', { action: removeSection, data: payload, mutation: MUTATION_REMOVE_SECTION })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? sectionModule : sectionAPI;