import {
    MUTATION_SET_ARMING_SETTINGS,
    MUTATION_SET_SECURITY_PANEL_SETTINGS
  } from "../mutation-types";

import {
    METHOD_GET_ARMING_SETTINGS,
    METHOD_GET_SECURITY_PANEL_SETTINGS,
    METHOD_SAVE_ARMING_SETTINGS,
    METHOD_SAVE_SECURITY_PANEL_SETTINGS
  } from "../method-names";

import { 
    getArmingSettings, 
    editArmingSettings, 
    getSecurityPanelSettings, 
    editSecurityPanelSettings 
} from "@/api/panel";

const panelSettingsModule = {
    state:{
        armingSettings:[],
        securityPanelSettings: {}
    },
    getters: {
        armingSettings: state => state.armingSettings,
        securityPanelSettings: state => state.securityPanelSettings
      },

    mutations: {
        [MUTATION_SET_ARMING_SETTINGS] (state, armingSettings) {
            state.armingSettings = armingSettings
        },
        [MUTATION_SET_SECURITY_PANEL_SETTINGS](state, settings){
            state.securityPanelSettings = settings
        }
    },

    actions:{
        getArmingSettings({dispatch}) {
            return dispatch('executeAction', { method: METHOD_GET_ARMING_SETTINGS })
        },

        getSecurityPanelSettings({dispatch}){
            return dispatch('executeAction', {method: METHOD_GET_SECURITY_PANEL_SETTINGS})
        },

        saveArmingSettings({dispatch}, payload) {
            return dispatch('executeAction', { method: METHOD_SAVE_ARMING_SETTINGS, data: payload })
        },        

        saveSecurityPanelSettings({dispatch}, payload){
            return dispatch('executeAction', { method: METHOD_SAVE_SECURITY_PANEL_SETTINGS, data: payload })
        },

        onReceivedArmingSettings ({dispatch}, event) {
            return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_ARMING_SETTINGS })
        },

        onSaveSecurityPanelSettings({dispatch}, event){
            return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_SECURITY_PANEL_SETTINGS })
        },

        onReceivedSecurityPanelSettings({dispatch}, event){
            return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_SECURITY_PANEL_SETTINGS })   
        },

        onSaveArmingSettings ({dispatch}, event) {
            return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_ARMING_SETTINGS })
        },        
    }
}

const panelSettingsAPI = {
    state:{
        armingSettings:[],
        securityPanelSettings: {}
    },
    getters: {
        armingSettings: state => state.armingSettings,
        securityPanelSettings: state => state.securityPanelSettings
      },

    mutations: {
        [MUTATION_SET_ARMING_SETTINGS] (state, armingSettings) {
            state.armingSettings = armingSettings
        },
        [MUTATION_SET_SECURITY_PANEL_SETTINGS](state, settings){
            state.securityPanelSettings = settings
        }
    },

    actions:{
        getArmingSettings({dispatch}) {
            return dispatch('runAction', { action: getArmingSettings, mutation: MUTATION_SET_ARMING_SETTINGS })
        },

        getSecurityPanelSettings({dispatch}){
            return dispatch('runAction', { action: getSecurityPanelSettings, mutation: MUTATION_SET_SECURITY_PANEL_SETTINGS })
        },

        saveArmingSettings({dispatch}, payload) {
            return dispatch('runAction', { action: editArmingSettings, data: payload, mutation: MUTATION_SET_ARMING_SETTINGS })
        },        

        saveSecurityPanelSettings({dispatch}, payload){
            return dispatch('runAction', { action: editSecurityPanelSettings, data: payload, mutation: MUTATION_SET_SECURITY_PANEL_SETTINGS })
        }     
    }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? panelSettingsModule : panelSettingsAPI;