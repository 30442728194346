import request from '@/api/relay-service'
import { METHOD_ADD_IPCAMERA, METHOD_EDIT_IPCAMERA, METHOD_GET_IPCAMERAS, METHOD_REMOVE_IPCAMERA, METHOD_SYNC_IPCAMERAS } from '../store/method-names'

export function getIPCameras(){
    return request({
        params: {
            action: METHOD_GET_IPCAMERAS
        },
        method: 'get'
    })
}

export function syncIPCameras(data){
    return request({
        params: {
            action: METHOD_SYNC_IPCAMERAS
        },
        method: 'post',
        data
    })
}

export function addIPCamera(data){
    return request({
        params: {
            action: METHOD_ADD_IPCAMERA
        },
        method: 'post',
        data
    })
}

export function editIPCamera(data){
    return request({
        params: {
            action: METHOD_EDIT_IPCAMERA
        },
        method: 'post',
        data
    })
}

export function removeIPCamera(id){
    return request({
        params: {
            action: METHOD_REMOVE_IPCAMERA,
            id: id
        },
        method: 'delete'
    })
}