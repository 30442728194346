import request from '@/api/relay-service'
import { METHOD_ADD_CONTACT, METHOD_EDIT_CONTACT, METHOD_GET_CONTACTS, METHOD_GET_INTERCOM_SETTINGS, METHOD_REMOVE_CONTACT, METHOD_SAVE_INTERCOM_SETTINGS, METHOD_SYNC_CONTACTS } from '../store/method-names'

export function getContacts(){
    return request({
        params: {
            action: METHOD_GET_CONTACTS
        },
        method: 'get'
    })
}

export function syncContacts(data){
    return request({
        params: {
            action: METHOD_SYNC_CONTACTS
        },
        method: 'post',
        data
    })
}

export function addContact(data){
    return request({
        params: {
            action: METHOD_ADD_CONTACT
        },
        method: 'post',
        data
    })
}

export function editContact(data){
    return request({
        params: {
            action: METHOD_EDIT_CONTACT
        },
        method: 'post',
        data
    })
}

export function removeContact(id){
    return request({
        params: {
            action: METHOD_REMOVE_CONTACT,
            id: id
        },
        method: 'delete'
    })
}


export function getIntercomSettings(){
    return request({
        params: {
            action: METHOD_GET_INTERCOM_SETTINGS
        },
        method: 'get'
    })
}

export function editIntercomSettings(data){
    return request({
        params: {
            action: METHOD_SAVE_INTERCOM_SETTINGS
        },
        method: 'post',
        data
    })
}