import {
  METHOD_ADD_ACCESSORY,
  METHOD_EDIT_ACCESSORY,
  METHOD_GET_ACCESSORIES,
  METHOD_REMOVE_ACCESSORY, METHOD_SYNC_ACCESSORIES
} from "../method-names";

import { 
  getAccessories, 
  syncAccessories, 
  addAccessory, 
  editAccessory, 
  removeAccessory 
} from "@/api/accessory";

import {
  MUTATION_ADD_ACCESSORY,
  MUTATION_EDIT_ACCESSORY,
  MUTATION_REMOVE_ACCESSORY,
  MUTATION_SET_ACCESSORIES,
} from "../mutation-types";

const accessoryModule = {
  state: {
    accessories: []
  },

  getters: {
    accessories: state => state.accessories,
    accessoryCount: state => state.accessories.length
  },

  mutations: {
    [MUTATION_SET_ACCESSORIES] (state, accessories) {
      state.accessories = accessories
    },

    [MUTATION_ADD_ACCESSORY] (state, accessory) {
      state.accessories.push(accessory)
    },

    [MUTATION_EDIT_ACCESSORY] (state, newAccessory) {
      state.accessories = state.accessories.map(accessory => {
        return accessory.id == newAccessory.id ? newAccessory : accessory
      })
    },

    [MUTATION_REMOVE_ACCESSORY] (state, accessoryId) {
      state.accessories = state.accessories.filter(accessory => accessory.id != accessoryId)
    }
  },

  actions: {

    /**
     * request method for all accessories
     * */
    getAccessories({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_ACCESSORIES })
    },

    /**
     * request method for sync Accessories
     *
     * @param payload accessory data
     * */
    syncAccessories({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SYNC_ACCESSORIES, data: payload })
    },

    /**
     * request method for adding Accessory
     *
     * @param payload accessory data
     * */
    addAccessory({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ADD_ACCESSORY, data: payload })
    },

    /**
     * request method for editing Accessory
     *
     * @param payload accessory data
     * */
    editAccessory({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_EDIT_ACCESSORY, data: payload })
    },

    /**
     * request method for removing Accessory
     *
     * @param payload accessory data
     * */
    removeAccessory({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_REMOVE_ACCESSORY, data: payload })
    },


    /**
     * response method for {@link getAccessories}
     *
     * @param event data from socket
     * */
    onReceivedAccessories ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_ACCESSORIES })
    },

    /**
     * response method for {@link syncAccessories}
     *
     * @param event data from socket
     * */
    onSyncAccessories ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_ACCESSORIES })
    },

    /**
     * response method for {@link addAccessory}
     *
     * @param event data from socket
     * */
    onAddAccessory ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ADD_ACCESSORY })
    },

    /**
     * response method for {@link editAccessory}
     *
     * @param event data from socket
     * */
    onEditAccessory ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_ACCESSORY })
    },

    /**
     * response method for {@link removeAccessory}
     *
     * @param event data from socket
     * */
    onRemoveAccessory ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REMOVE_ACCESSORY })
    }
  },
}

const accessoryAPI = {
  state: {
    accessories: []
  },

  getters: {
    accessories: state => state.accessories,
    accessoryCount: state => state.accessories.length
  },

  mutations: {
    [MUTATION_SET_ACCESSORIES] (state, accessories) {
      state.accessories = accessories
    },

    [MUTATION_ADD_ACCESSORY] (state, accessory) {
      state.accessories.push(accessory)
    },

    [MUTATION_EDIT_ACCESSORY] (state, newAccessory) {
      state.accessories = state.accessories.map(accessory => {
        return accessory.id == newAccessory.id ? newAccessory : accessory
      })
    },

    [MUTATION_REMOVE_ACCESSORY] (state, accessoryId) {
      state.accessories = state.accessories.filter(accessory => accessory.id != accessoryId)
    }
  },

  actions: {

    /**
     * request method for all accessories
     * */
    getAccessories({dispatch}) {
      return dispatch('runAction', { action: getAccessories, mutation: MUTATION_SET_ACCESSORIES })
    },

    /**
     * request method for sync Accessories
     *
     * @param payload accessory data
     * */
    syncAccessories({dispatch}, payload) {
      return dispatch('runAction', { action: syncAccessories, data: payload, mutation: MUTATION_SET_ACCESSORIES })
    },

    /**
     * request method for adding Accessory
     *
     * @param payload accessory data
     * */
    addAccessory({dispatch}, payload) {
      return dispatch('runAction', { action: addAccessory, data: payload, mutation: MUTATION_ADD_ACCESSORY })
    },

    /**
     * request method for editing Accessory
     *
     * @param payload accessory data
     * */
    editAccessory({dispatch}, payload) {
      return dispatch('runAction', { action: editAccessory, data: payload, mutation: MUTATION_EDIT_ACCESSORY })
    },

    /**
     * request method for removing Accessory
     *
     * @param payload accessory data
     * */
    removeAccessory({dispatch}, payload) {
      return dispatch('runAction', { action: removeAccessory, data: payload, mutation: MUTATION_REMOVE_ACCESSORY })
    }
  },
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? accessoryModule : accessoryAPI;