import request from '@/api/relay-service'
import { METHOD_ADD_DRIVER, METHOD_EDIT_DRIVER, METHOD_GET_DRIVERS, METHOD_REMOVE_DRIVER, METHOD_SYNC_DRIVERS } from '../store/method-names'

export function getDrivers(){
    return request({
        params: {
            action: METHOD_GET_DRIVERS
        },
        method: 'get'
    })
}

export function syncDrivers(data){
    return request({
        params: {
            action: METHOD_SYNC_DRIVERS
        },
        method: 'post',
        data
    })
}

export function refreshDriver(data){
    return request({
        params: {
            action: METHOD_ADD_DRIVER
        },
        method: 'post',
        data
    })
}

export function addDriver(data){
    return request({
        params: {
            action: METHOD_ADD_DRIVER
        },
        method: 'post',
        data
    })
}

export function editDriver(data){
    return request({
        params: {
            action: METHOD_EDIT_DRIVER
        },
        method: 'post',
        data
    })
}

export function removeDriver(id){
    return request({
        params: {
            action: METHOD_REMOVE_DRIVER,
            id: id
        },
        method: 'delete'
    })
}