import request from '@/api/relay-service'
import { METHOD_ENABLE_ADB, METHOD_ENABLE_OTG, METHOD_PLAY_SOUND, METHOD_REBOOT_DEVICE, METHOD_RUN_SIP_ACTION } from '../store/method-names'

export function playAudio(data){
    return request({
        params: {
            action: METHOD_PLAY_SOUND
        },
        method: 'post',
        data: {sound: data}
    })
}

export function enableADB(enable){
    return request({
        params: {
            action: METHOD_ENABLE_ADB
        },
        method: 'post',
        data: {enable: enable}
    })
}

export function enableOTG(enable){
    return request({
        params: {
            action: METHOD_ENABLE_OTG
        },
        method: 'post',
        data: {enable: enable}
    })
}

export function runSipAction(data){
    return request({
        params: {
            action: METHOD_RUN_SIP_ACTION
        },
        method: 'post',
        data
    })
}

export function rebootDevice(){
    return request({
        params: {
            action: METHOD_REBOOT_DEVICE
        },
        method: 'post'
    })
}