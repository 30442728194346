var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "bg-eae": _vm.brand == "EAE", bg: _vm.brand != "EAE" } },
    [
      _c(
        "div",
        { staticClass: "lang-menu" },
        [
          _c(
            "el-dropdown",
            {
              attrs: { trigger: "click" },
              on: { command: _vm.selectLanguage }
            },
            [
              _c(
                "span",
                {
                  staticClass: "el-dropdown-link",
                  staticStyle: { color: "#eeeeee" }
                },
                [
                  _c("img", {
                    class: _vm.currentLang.icon,
                    attrs: { src: require("@/assets/blank.gif") }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t(_vm.currentLang.title)))
                ]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.langs, function(lang) {
                  return _c(
                    "el-dropdown-item",
                    { key: lang.code, attrs: { command: lang } },
                    [
                      _c("img", {
                        class: lang.icon,
                        attrs: { src: require("@/assets/blank.gif") }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t(lang.title)))
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "login-container" },
        [
          _c(
            "el-form",
            {
              ref: "userData",
              staticStyle: { width: "300px" },
              attrs: {
                model: _vm.userData,
                rules: _vm.rules,
                "label-position": "center"
              }
            },
            [
              _c("h3", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("auth.login")))
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "fa fa-user-circle",
                      type: "text",
                      "auto-complete": "on",
                      placeholder: _vm.$t("auth.enter-uname")
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submitLoginForm($event)
                      }
                    },
                    model: {
                      value: _vm.userData.username,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "username", $$v)
                      },
                      expression: "userData.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "fa fa-key",
                      type: "password",
                      "auto-complete": "off",
                      placeholder: _vm.$t("auth.enter-pwd")
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submitLoginForm($event)
                      }
                    },
                    model: {
                      value: _vm.userData.password,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "password", $$v)
                      },
                      expression: "userData.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "left" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.logining },
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submitLoginForm($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("auth.sign-in")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }