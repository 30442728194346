var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-aside",
    {
      attrs: {
        width: _vm.$store.getters.isMenuCollapsed ? 64 + "px" : 280 + "px"
      }
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$store.getters.isMenuCollapsed,
              expression: "!$store.getters.isMenuCollapsed"
            }
          ]
        },
        [
          _c("el-col", { staticClass: "logo" }, [
            _c("h3", { staticStyle: { color: "#ffd04b" } }, [
              _vm._v(_vm._s(_vm.brandTitle))
            ])
          ]),
          _c(
            "el-col",
            [
              _c(
                "el-menu",
                {
                  attrs: {
                    "unique-opened": "",
                    "background-color": "#fcfcfc",
                    "text-color": "#000",
                    "default-active": _vm.$route.path,
                    router: ""
                  }
                },
                [
                  _vm._l(_vm.$router.options.routes, function(item, index) {
                    return [
                      !item.hidden &&
                      !item.leaf &&
                      _vm.showAdvancedMenu(item.advanced) &&
                      _vm.showByBrand(item.brand)
                        ? _c(
                            "el-submenu",
                            {
                              key: "route-" + index + "-" + item.path,
                              attrs: { index: index + "" }
                            },
                            [
                              _c(
                                "template",
                                { staticClass: "menu-item", slot: "title" },
                                [
                                  _c("div", { staticClass: "menu-item" }, [
                                    _c("img", {
                                      staticClass: "sidebar-icon",
                                      attrs: {
                                        src: _vm.getImgPath(item.meta.icon)
                                      }
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.getTitle(item.meta.title))
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _vm._l(item.children, function(child) {
                                return [
                                  !child.hidden &&
                                  _vm.showAdvancedMenu(child.advanced) &&
                                  _vm.showByBrand(child.brand)
                                    ? _c(
                                        "el-menu-item",
                                        {
                                          key: child.path,
                                          attrs: {
                                            route: child,
                                            index: child.path
                                          },
                                          on: { click: _vm.handleMenuClick }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "menu-item" },
                                            [
                                              _c("img", {
                                                staticClass: "sidebar-icon",
                                                attrs: {
                                                  src: _vm.getImgPath(
                                                    child.meta.icon
                                                  )
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getTitle(
                                                      child.meta.title
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    : child.hidden &&
                                      child.children &&
                                      child.children.length > 0
                                    ? _vm._l(child.children, function(
                                        grandchild
                                      ) {
                                        return !grandchild.hidden
                                          ? _c(
                                              "el-menu-item",
                                              {
                                                key: grandchild.path,
                                                attrs: {
                                                  index: grandchild.path,
                                                  route: grandchild
                                                },
                                                on: {
                                                  click: _vm.handleMenuClick
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "menu-item" },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "sidebar-icon",
                                                      attrs: {
                                                        src: _vm.getImgPath(
                                                          grandchild.meta.icon
                                                        )
                                                      }
                                                    }),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getTitle(
                                                            grandchild.meta
                                                              .title
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      })
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      item.leaf && item.children.length > 0
                        ? _c(
                            "el-menu-item",
                            {
                              key: item.children[0].path,
                              attrs: {
                                index: item.children[0].path,
                                route: item
                              },
                              on: { click: _vm.handleMenuClick }
                            },
                            [
                              _c("div", { staticClass: "menu-item" }, [
                                _c("img", {
                                  staticClass: "sidebar-icon",
                                  attrs: {
                                    src: _vm.getImgPath(
                                      item.children[0].meta.icon
                                    )
                                  }
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getTitle(item.children[0].meta.title)
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("el-col", [
            _c("small", { staticClass: "copyright" }, [
              _vm._v("2020 "),
              _c("i", { staticClass: "far fa-copyright" }),
              _vm._v(" " + _vm._s(_vm.copyright) + " - v4.0.10")
            ])
          ])
        ],
        1
      ),
      _c("PopupMenu")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }