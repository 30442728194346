import {
  MUTATION_ADD_ROOM,
  MUTATION_EDIT_ROOM,
  MUTATION_REMOVE_ROOM,
  MUTATION_SET_ROOMS
} from "../mutation-types";
import {
  METHOD_ADD_ROOM,
  METHOD_EDIT_ROOM,
  METHOD_GET_ROOMS,
  METHOD_REMOVE_ROOM, METHOD_SYNC_ROOMS
} from "../method-names";

import { 
  getRooms, 
  addRoom, 
  editRoom, 
  removeRoom, 
  syncRooms 
} from "@/api/room";

const roomModule = {
  state: {
    rooms: []
  },

  getters: {
    rooms: state => state.rooms,
    roomCount: state => state.rooms.length
  },

  mutations: {
    [MUTATION_SET_ROOMS] (state, rooms) {
      state.rooms = rooms
    },

    [MUTATION_ADD_ROOM] (state, room) {
      state.rooms.push(room)
    },

    [MUTATION_EDIT_ROOM] (state, newRoom) {
      state.rooms = state.rooms.map(room => {
        return room.id == newRoom.id ? newRoom : room
      })
    },

    [MUTATION_REMOVE_ROOM] (state, roomId) {
      state.rooms = state.rooms.filter(room => room.id != roomId)
    }
  },

  actions: {
    /**
     * request method for all rooms
     * */
    getRooms({dispatch}) {
      return dispatch('executeAction', { method: METHOD_GET_ROOMS })
    },

    /**
     * request method for adding Room
     *
     * @param payload room data
     * */
    syncRooms({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_SYNC_ROOMS, data: payload })
    },

    /**
     * request method for adding Room
     *
     * @param payload room data
     * */
    addRoom({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_ADD_ROOM, data: payload })
    },

    /**
     * request method for editting Room
     *
     * @param payload room data
     * */
    editRoom({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_EDIT_ROOM, data: payload })
    },

    /**
     * request method for removing Room
     *
     * @param payload room data
     * */
    removeRoom({dispatch}, payload) {
      return dispatch('executeAction', { method: METHOD_REMOVE_ROOM, data: payload })
    },


    /**
     * response method for {@link getRooms}
     *
     * @param event data from socket
     * */
    onReceivedRooms ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_ROOMS })
    },

    /**
     * response method for {@link syncRooms}
     *
     * @param event data from socket
     * */
    onSyncRooms ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_SET_ROOMS })
    },

    /**
     * response method for {@link addRoom}
     *
     * @param event data from socket
     * */
    onAddRoom ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_ADD_ROOM })
    },

    /**
     * response method for {@link editRoom}
     *
     * @param event data from socket
     * */
    onEditRoom ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_EDIT_ROOM})
    },

    /**
     * response method for {@link removeRoom}
     *
     * @param event data from socket
     * */
    onRemoveRoom ({dispatch}, event) {
      return dispatch('onReceivedEvent', { event: event, mutation: MUTATION_REMOVE_ROOM})
    }
  }
}

const roomAPI = {
  state: {
    rooms: []
  },

  getters: {
    rooms: state => state.rooms,
    roomCount: state => state.rooms.length
  },

  mutations: {
    [MUTATION_SET_ROOMS] (state, rooms) {
      state.rooms = rooms
    },

    [MUTATION_ADD_ROOM] (state, room) {
      state.rooms.push(room)
    },

    [MUTATION_EDIT_ROOM] (state, newRoom) {
      state.rooms = state.rooms.map(room => {
        return room.id == newRoom.id ? newRoom : room
      })
    },

    [MUTATION_REMOVE_ROOM] (state, roomId) {
      state.rooms = state.rooms.filter(room => room.id != roomId)
    }
  },

  actions: {
    /**
     * request method for all rooms
     * */
    getRooms({dispatch}) {
      return dispatch('runAction', { action: getRooms, mutation: MUTATION_SET_ROOMS })
    },

    /**
     * request method for adding Room
     *
     * @param payload room data
     * */
    syncRooms({dispatch}, payload) {
      return dispatch('runAction', { action: syncRooms, data: payload, mutation: MUTATION_SET_ROOMS })
    },


    /**
     * request method for adding Room
     *
     * @param payload room data
     * */
    addRoom({dispatch}, payload) {
      return dispatch('runAction', { action: addRoom, data: payload, mutation: MUTATION_ADD_ROOM })
    },

    /**
     * request method for editting Room
     *
     * @param payload room data
     * */
    editRoom({dispatch}, payload) {
      return dispatch('runAction', { action: editRoom, data: payload, mutation: MUTATION_EDIT_ROOM })
    },

    /**
     * request method for removing Room
     *
     * @param payload room data
     * */
    removeRoom({dispatch}, payload) {
      return dispatch('runAction', { action: removeRoom, data: payload, mutation: MUTATION_REMOVE_ROOM })
    }
  }
}

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? roomModule : roomAPI;