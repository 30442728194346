<template>

  <el-container class="rootlayout">

    <app-sidebar v-if="$store.getters.isConnectedDevice || $store.getters.isSidebarVisible"></app-sidebar>

    <el-container>

      <el-header v-show="$store.getters.technicalAccessCode || $store.getters.isLocalMode || $store.getters.roles.maintenance" height="80px">
        <app-topbar></app-topbar>
      </el-header>
      <!-- <el-header>
        <app-topbar></app-topbar>
      </el-header> -->
      <el-main>
        <router-view></router-view>
      </el-main>


    </el-container>

  </el-container>
</template>

<script>
  import SideBar from './components/sidebar'
  import Topbar from './components/topbar'

  export default {
    name: "Home",
    components: {
      'app-sidebar': SideBar,
      'app-topbar': Topbar
    },


    methods: {

      onGetDrivers(event) {
        this.$store.dispatch('getDrivers').then(message => {
          this.$message({
            type: "success", message: message
          })
        }).catch(error => {
          this.$message({
            type: "error", message: error
          })
        })
      },
    }
  }
</script>

<style scoped>
  .rootlayout {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }

 .el-main {
   padding: 20px;
   background-color: #f2f2f2;
 }

  .el-header {
    box-shadow: 0px 0px 25px #333;
    background-color: #f2f2f2;
  }
</style>

