import Login from '../views/auth/Login'
import Home from '../views/home/Home'

export const routes = [
  {
    path: "/", redirect: "/dashboard", component: Home, name: "Home",
    leaf: true,
    children: [
      {
        path: "dashboard", name: "Dashboard",
        component: () => import('../views/dashboard/index'),
        meta: {
          title: "main-page",
          icon: "dashboard"
        }
      }
    ]
  },

  {
    path: "/", redirect: "/access-code", component: Home, name: "Home",
    hidden: true,
    children: [
      {
        path: "access-code", name: "AccessCode",
        component: () => import('../views/auth/AccessCode'),
        meta: {
          title: "access-code",
          icon: "dashboard"
        }
      }
    ]
  },

  {
    path: "/", redirect: "/maintenance", component: Home, name: "Home",
    hidden: true,
    // leaf: true,
    children: [
      {
        path: "maintenance", name: "Maintenance",
        component: () => import('../views/maintenance/Maintenance'),
        meta: {
          title: "maintenance",
          icon: "dashboard"
        }
      }
    ]
  },

  {
    path: "/", component: Home, name: "Devices",
    meta: {
      title: "devices",
      icon: "devices"
    },
    children: [
      {
        path: "driver",
        component: () => import('../views/driver/index'),
        hidden: true,
        children: [
          {
            path: "drivers", name: "Drivers",
            component: () => import('../views/driver/Drivers'),
            meta: {
              title: "drivers",
              icon: "driver"
            }
          },

          {
            path: "edit/:id", name: "DriverEdit",
            component: () => import('../views/driver/DriverEdit'),
            hidden: true,
            meta: {
              title: "driver-edit"

            }
          },

          {
            path: "add", name: "DriverAdd",
            component: () => import('../views/driver/NewDriver'),
            hidden: true,
            meta: {
              title: "driver-add"
            }
          },
        ]
      },


      {
        path: "accessory", redirect: "accessory/list",
        component: () => import('../views/accessory/index'),
        hidden: true,
        children: [
          {
            path: "accessories", name: "Accessories",
            component: () => import('../views/accessory/Accessories'),
            meta: {
              title: "accessories",
              icon: "accessories"
            }
          },

          {
            path: "edit/:id", name: "AccessoryEdit",
            component: () => import('../views/accessory/AccessoryEdit'),
            hidden: true,
            meta: {
              title: "accessory-edit"
            }
          },

          {
            path: "add", name: "AccessoryAdd",
            component: () => import('../views/accessory/NewAccessory'),
            hidden: true,
            meta: {
              title: "accessory-add"
            }
          },
        ]
      },


      {
        path: "room", redirect: "rooms/list",
        component: () => import('../views/room/index'),
        hidden: true,
        children: [
          {
            path: "rooms", name: "Rooms",
            component: () => import('../views/room/Rooms'),
            meta: {
              title: "rooms",
              icon: "rooms"
            }
          }
        ],
      },
      {
        path: "controls", name: "Controls",
        component: () => import('../views/control/Control'),
        advanced: true,
        meta: {
          title: "controls",
          icon: "general"
        }
      },
    ]
  },

  {
    path: "/intercom", component: Home, name: "Intercom",
    meta: {
      title: "intercom",
      icon: "intercom"
    },
    children: [
      {
        path: "settings", name: "IntercomSettings",
        component: () => import('../views/intercom/Settings'),
        meta: {
          title: "intercom-settings",
          icon: "intercom-settings"
        }
      },
      {
        path: "contacts", name: "Contacts",
        component: () => import('../views/intercom/Contacts'),
        meta: {
          title: "contacts",
          icon: "intercom-contacts"
        }
      },
      {
        path: "add", name: "ContactAdd",
        component: () => import('../views/intercom/NewContact'),
        hidden: true,
        meta: {
          title: "contact-add",
          icon: "dashboard"
        }
      },
      {
        path: "edit/:id", name: "ContactEdit",
        component: () => import('../views/intercom/NewContact'),
        hidden: true,
        meta: {
          title: "contact-edit",
          icon: "dashboard"
        }
      },
    ]
  },


  {
    path: "/automation", component: Home, name: "SceneHome",
    meta: {
      title: "scene-automation",
      icon: "automation"
    },
    children: [
      {
        path: "scenes", name: "Scenes",
        component: () => import('../views/automation/scene/Scenes'),
        meta: {
          title: "scenes",
          icon: "scenes"
        }
      },
      {
        path: "add", name: "SceneAdd",
        component: () => import('../views/automation/scene/NewScene'),
        hidden: true,
        meta: {
          title: "scene-add",
          icon: "dashboard"
        }
      },
      {
        path: "edit", name: "SceneEdit",
        component: () => import('../views/automation/scene/SceneEdit'),
        hidden: true,
        meta: {
          title: "scene-edit",
          icon: "dashboard"
        }
      },

      {
        path: "triggers", name: "Triggers",
        component: () => import('../views/automation/trigger/Triggers'),
        hidden: false,
        meta: {
          title: "triggers",
          icon: "triggers"
        }
      },
      {
        path: "add-trigger", name: "TriggerAdd",
        component: () => import('../views/automation/trigger/NewTrigger'),
        hidden: true,
        meta: {
          title: "trigger-add",
          icon: "dashboard"
        }
      },
      {
        path: "edit-trigger/:id", name: "TriggerEdit",
        component: () => import('../views/automation/trigger/NewTrigger'),
        hidden: true,
        meta: {
          title: "trigger-edit",
          icon: "dashboard"
        }
      },
      {
        path: "trigger-types", name: "TriggerTypes",
        component: () => import('../views/automation/trigger/components/TriggerTypes'),
        hidden: true,
        meta: {
          title: "trigger-types",
          icon: "dashboard"
        }
      },
      {
        path: "when", name: "When",
        component: () => import('../views/automation/trigger/components/When'),
        hidden: true,
        meta: {
          title: "when",
          icon: "dashboard"
        }
      },
      {
        path: "when/:edit", name: "WhenEdit",
        component: () => import('../views/automation/trigger/components/When'),
        hidden: true,
        meta: {
          title: "when",
          icon: "dashboard"
        }
      },
      {
        path: "when-schedule", name: "WhenSchedule",
        component: () => import('../views/automation/trigger/components/WhenSchedule'),
        hidden: true,
        meta: {
          title: "when-schedule",
          icon: "dashboard"
        }
      },
      {
        path: "when-schedule/:edit", name: "WhenScheduleEdit",
        component: () => import('../views/automation/trigger/components/WhenSchedule'),
        hidden: true,
        meta: {
          title: "when-schedule",
          icon: "dashboard"
        }
      },
      {
        path: "then", name: "Then",
        component: () => import('../views/automation/trigger/components/Then'),
        hidden: true,
        meta: {
          title: "then",
          icon: "dashboard"
        }
      },

    ]
  },

  {
    path: "/security", component: Home, name: "SecurityHome",
    meta: {
      title: "security",
      icon: "security"
    },
    children: [
      {
        path: "ipcameras", name: "IPCameras",
        component: () => import('../views/security/IPCameras'),
        meta: {
          title: "ip-cameras",
          icon: "ip-cameras"
        }
      },
      {
        path: "panel-settings", name: "PanelSettings",
        component: () => import('../views/security/settings/PanelSettings'),
        meta: {
          title: "panelSettings",
          icon: "panel-settings"
        }
      },
      {
        path: "add", name: "IPCameraAdd",
        component: () => import('../views/security/IPCameraAddOrEdit'),
        hidden: true,
        meta: {
          title: "ip-camera-add",
          icon: "dashboard"
        }
      },
      {
        path: "edit", name: "IPCameraEdit",
        component: () => import('../views/security/IPCameraAddOrEdit'),
        hidden: true,
        meta: {
          title: "ip-camera-edit",
          icon: "dashboard"
        }
      },

    ]
  },


  {
    path: "/ui", component: Home, name: "ui",
    meta: {
      title: "ui-settings",
      icon: "ui-settings"
    },
    children: [
      {
        path: "widgets", name: "Widgets",
        component: () => import('../views/ui/Widgets'),
        meta: {
          title: "widgets",
          icon: "widgets"
        }
      },
      {
        path: "menu-items", name: "MenuItems",
        component: () => import('../views/ui/MenuItems'),
        meta: {
          title: "MenuItems",
          icon: "menu-items"
        }
      },
    ]
  },



  {
    path: "/", component: Home, name: "Settings",
    meta: {
      title: "settings",
      icon: "system-settings"
    },
    children: [
      {
        path: "import-export", name: "ImportExport",
        component: () => import('../views/settings/importExport/index'),
        meta: {
          title: "import-export",
          icon: "import-export"
        }
      },
      {
        path: "general-settings", name: "General",
        component: () => import('../views/settings/general/General'),
        meta: {
          title: "general-settings",
          icon: "general-settings"
        }
      },
      {
        path: "network-settings", name: "Network",
        component: () => import('../views/settings/network/Network'),
        meta: {
          title: "network-settings",
          icon: "network-settings"
        }
      },

      {
        path: "rss-settings", name: "RSS",
        component: () => import('../views/settings/rss/RSSSettings'),
        meta: {
          title: "rss-settings",
          icon: "rss-settings"
        }
      },

      {
        path: "security-settings", name: "Security",
        component: () => import('../views/settings/security/Security'),
        meta: {
          title: "security-settings",
          icon: "security-settings"
        }
      },
      {
        path: "log-settings", name: "Logs",
        component: () => import('../views/settings/logs/Logs'),
        brand: "CR",
        meta: {
          title: "log-settings",
          icon: "intercom-settings"
        }
      },
      {
        path: "advanced-settings", name: "Advanced",
        component: () => import('../views/settings/advanced/Advanced'),
        advanced: true,
        meta: {
          title: "advanced-settings",
          icon: "system-settings"
        }
      },
    ]
  },



  {
    path: "/login", name: "Login", component: Login, hidden: true
  },

  {
    path: "*", component: () => import('../views/NotFound'), hidden: true
  }
]