import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import { routes } from './routes'

const router = new VueRouter({
  routes: routes,
  mode: "history"
})

Vue.use(VueRouter);

router.beforeEach((to, from, next) => {
  const connected = store.getters.isConnectedDevice
  const roles = store.getters.roles
  const remoteMode = process.env.VUE_APP_REMOTE_MODE == 'true'

  // #region !isSignedIn
  if (!store.getters.isSignedIn) {
    if (to.name === 'Login') {
      return next()
    }
    return next('/login')
  }
  // #endregion
  // #region isSignedIn
  // #region !remoteMode
  if (!remoteMode) {
    return next()
  }
  // #endregion
  // #region remoteMode
  // #region maintenance role
  if (!roles.manager && roles.maintenance) {
    if (to.name !== 'Login' && to.name !== 'Maintenance') {
      return next('/maintenance')
    }
  }
  if (to.name === 'Maintenance') {
    return next()
  }
  // #endregion
  // #region AccessCode
  if (to.name === 'AccessCode' && connected) {
    return next('/dashboard')
  }
  // next page other and not connected
  if (to.name !== 'AccessCode' && to.name !== 'Login' && !connected) {
    return next('/access-code')
  }
  // #endregion
  // #endregion
  // #endregion
  return next()
})


export default router;