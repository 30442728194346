const KEY_TOKEN = "core-dp-token"
const KEY_USER_ID = "core-dp-userid"
const KEY_DEVICE_ID = "core-dp-device-id"
const KEY_ACCESS_CODE = "core-dp-access-code"
const KEY_EXPIRE_DATE = "core-dp-expire-date"
const KEY_ROLES = "core-dp-roles"

export function getToken(){
    return localStorage.getItem(KEY_TOKEN)
}

export function setToken(token) {
    localStorage.setItem(KEY_TOKEN, token)
}

export function getExpireDate(){
    return localStorage.getItem(KEY_EXPIRE_DATE)
}

export function setExpireDate(expireDate) {
    localStorage.setItem(KEY_EXPIRE_DATE, expireDate)
}

export function removeToken(){
    localStorage.removeItem(KEY_TOKEN)
}

export function getUserId(){
    return localStorage.getItem(KEY_USER_ID)
}

export function setUserId(userId) {
    localStorage.setItem(KEY_USER_ID, userId)
}

export function removeUserId() {
    localStorage.removeItem(KEY_USER_ID)
}

export function getDeviceId() {
    return localStorage.getItem(KEY_DEVICE_ID)
}

export function setDeviceId(deviceId) {
    localStorage.setItem(KEY_DEVICE_ID, deviceId)
}

export function removeDeviceId() {
    localStorage.removeItem(KEY_DEVICE_ID)
}

export function getTechnicalAccessCode() {
    return localStorage.getItem(KEY_ACCESS_CODE)
}

export function setTechnicalAccessCode(accessCode) {
    localStorage.setItem(KEY_ACCESS_CODE, accessCode)
}

export function removeTechnicalAccessCode() {
    localStorage.removeItem(KEY_ACCESS_CODE)
}

export function setRoles(roles) {
    localStorage.setItem(KEY_ROLES, JSON.stringify(roles))
}

export function getRoles() {
    return JSON.parse(localStorage.getItem(KEY_ROLES) || '{}')
}