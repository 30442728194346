import {
  MUTATION_SET_GENERAL_SETTINGS,
  MUTATION_SET_GENERAL_INFO,
  MUTATION_SET_CONNECTED_DEVICE_ID,
  MUTATION_SET_TECHNICAL_ACCESS_CODE,
} from "../mutation-types";
import {
  METHOD_GET_GENERAL_SETTINGS,
  METHOD_GET_GENERAL_INFO,
  METHOD_SAVE_GENERAL_SETTINGS,
} from "../method-names";
import { 
  getGeneralInfo, 
  getGeneralSettings, 
  editGeneralSettings 
} from "@/api/general";
import { setDeviceInfo, getDeviceInfo } from "../../utils/device";

const generalModule = {
  state: {
    generalSettingsFormData: {},

    generalInfo: [],

    project: {
      projectName: "",
      blockName: "",
      floorNumber: 0,
      flatNumber: 0,
      city: "",
      state: "",
      country: "",
    },

    ringtone: {
      doorbellRingtone: "",
      doorbellRepeatCount: 1,
      intercomRingtone: "",
    },

    screenSaver: {
      type: 1,
      delayTime: 1,
      password: "",
    },

    weather: {
      unit: "F",
      pollingPeriod: 1,
    },

    device: {
      name: "",
      language: "tr",
      isSlave: false,
      masterIp: "",
      background: {
        imageUrl: "",
        name: "",
        blur: true,
      },
    },

    dateTime: {
      currentGMT: "",
      availableTimeZones: [],
      ntpServer: "",
    },

    knxSettings: {
      tpuartSourceAddress: "",
      discardBusErrors: false,
    },
  },

  getters: {
    generalInfo: (state) => state.generalInfo,
    project: (state) => state.project,
    ringtone: (state) => state.ringtone,
    screenSaver: (state) => state.screenSaver,
    weather: (state) => state.weather,
    device: (state) => state.device,
    dateTime: (state) => state.dateTime,
    knxSettings: (state) => state.knxSettings,
    generalSettingsFormData: (state) => state.generalSettingsFormData,
  },

  mutations: {
    [MUTATION_SET_GENERAL_SETTINGS](state, settings) {
      state.project = settings.project;
      state.screenSaver = settings.screenSaver;
      state.weather = settings.weather;
      state.device = settings.device;
      state.ringtone = settings.ringtone;
      state.dateTime = settings.dateTime;
      state.knxSettings = settings.knxSettings;
    },

    [MUTATION_SET_GENERAL_INFO](state, info) {
      state.generalInfo = [
        { title: "general.device-name", value: info.deviceName },
        { title: "general.device-os", value: info.os },
        { title: "general.device-model", value: info.model },
        { title: "general.product-code", value: info.productCode },
        { title: "general.serial-number", value: info.serialNumber },
        { title: "general.eth0-mac-addr", value: info.eth0MacAddr },
        { title: "general.eth1-mac-addr", value: info.eth1MacAddr },
        { title: "general.coreos-version", value: info.version },
        { title: "general.location", value: info.location },
      ];

      //state.generalInfo = generalInfo;
    },

    setGeneralSettingsFormData(state, settings) {
      state.generalSettingsFormData = settings;
    },
  },

  actions: {
    getGeneralSettings({ dispatch }) {
      return dispatch("executeAction", { method: METHOD_GET_GENERAL_SETTINGS });
    },

    getGeneralInfo({ dispatch }) {
      return dispatch("executeAction", { method: METHOD_GET_GENERAL_INFO });
    },

    saveGeneralSettings({ dispatch }, payload) {
      //return dispatch('executeAction', { method: METHOD_SAVE_GENERAL_SETTINGS })
      return dispatch("executeAction", {
        method: METHOD_SAVE_GENERAL_SETTINGS,
        data: payload,
      });
    },

    onEditGeneralSettings({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_SET_GENERAL_SETTINGS,
      });
    },

    onReceivedGeneralSettings({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_SET_GENERAL_SETTINGS,
      });
    },

    onReceivedGeneralInfo({ dispatch }, event) {
      return dispatch("onReceivedEvent", {
        event: event,
        mutation: MUTATION_SET_GENERAL_INFO,
      });
    },
  },
};

const generalAPI = {
  state: {
    generalSettingsFormData: {},

    generalInfo: [],

    productCode: "",
    brand: "",
    relayType: 1,
    existsRS232: true,
    existsRS485: true,
    serialNumber: "",
    coreosVersion: "",

    project: {
      projectName: "",
      blockName: "",
      floorNumber: 0,
      flatNumber: 0,
      city: "",
      state: "",
      country: "",
    },

    ringtone: {
      doorbellRingtone: "",
      doorbellRepeatCount: 1,
      intercomRingtone: "",
    },

    screenSaver: {
      type: 1,
      delayTime: 1,
      password: "",
    },

    weather: {
      unit: "F",
      pollingPeriod: 1,
    },

    device: {
      name: "",
      language: "tr",
      isSlave: false,
      masterIp: "",
      background: {
        imageUrl: "",
        name: "",
        blur: true,
      },
    },

    dateTime: {
      currentGMT: "",
      availableTimeZones: [],
      ntpServer: "",
    },

    knxSettings: {
      tpuartSourceAddress: "",
      discardBusErrors: false,
    },
  },

  getters: {
    productCode: (state) => state.productCode,
    relayType: (state) => state.relayType,
    existsRS232: (state) => state.existsRS232,
    existsRS485: (state) => state.existsRS485,
    brand: (state) => state.brand,
    serialNumber: (state) => state.serialNumber,
    coreosVersion: (state) => state.coreosVersion,

    generalInfo: (state) => state.generalInfo,
    project: (state) => state.project,
    ringtone: (state) => state.ringtone,
    screenSaver: (state) => state.screenSaver,
    weather: (state) => state.weather,
    device: (state) => state.device,
    dateTime: (state) => state.dateTime,
    knxSettings: (state) => state.knxSettings,
    generalSettingsFormData: (state) => state.generalSettingsFormData
  },

  mutations: {
    [MUTATION_SET_GENERAL_SETTINGS](state, settings) {
      state.project = settings.project;
      state.screenSaver = settings.screenSaver;
      state.weather = settings.weather;
      state.device = settings.device;
      state.ringtone = settings.ringtone;
      state.dateTime = settings.dateTime;
      state.knxSettings = settings.knxSettings;
    },

    [MUTATION_SET_GENERAL_INFO](state, info) {
      state.productCode = info.productCode
      state.brand = info.brand
      state.relayType = info.relayType
      state.existsRS232 = info.RS232
      state.existsRS485 = info.RS485
      state.serialNumber = info.serialNumber
      state.coreosVersion = info.version

      state.generalInfo = [
        { title: "general.device-name", value: info.deviceName },
        { title: "general.device-os", value: info.os },
        { title: "general.device-model", value: info.model },
        { title: "general.product-code", value: info.productCode },
        { title: "general.serial-number", value: info.serialNumber },
        { title: "general.eth0-mac-addr", value: info.eth0MacAddr },
        { title: "general.eth1-mac-addr", value: info.eth1MacAddr },
        { title: "general.coreos-version", value: info.version },
        { title: "general.location", value: info.location }
      ];

      setDeviceInfo(info)
    },

    setGeneralSettingsFormData(state, settings) {
      state.generalSettingsFormData = settings;
    },
  },

  actions: {
    getGeneralSettings({ dispatch }) {
      return dispatch('runAction', { action: getGeneralSettings, mutation: MUTATION_SET_GENERAL_SETTINGS })
    },

    getGeneralInfo({ dispatch }) {
      return dispatch('runAction', { action: getGeneralInfo, mutation: MUTATION_SET_GENERAL_INFO })
    },

    saveGeneralSettings({ dispatch }, payload) {
      return dispatch('runAction', { action: editGeneralSettings, data: payload, mutation: MUTATION_SET_GENERAL_SETTINGS })
    },

    initDeviceInfo({ commit }) {
      const info = getDeviceInfo()
      commit(MUTATION_SET_GENERAL_INFO, info)
    },

    resetDeviceInfo({ commit }) {
      commit(MUTATION_SET_GENERAL_INFO, {})
      commit(MUTATION_SET_CONNECTED_DEVICE_ID, "");
      commit(MUTATION_SET_TECHNICAL_ACCESS_CODE, "");
    },
  }
};

export default 
  process.env.VUE_APP_REMOTE_MODE == 'false' ? generalModule : generalAPI;
