import axios from "axios"
import { getUserId, getToken } from "@/api/auth"

const service = axios.create({
    baseURL: `${process.env.VUE_APP_AUTH_SERVER_URL}/api/v1/`,
    crossDomain: true,
    timeout: 5000
})


service.interceptors.request.use(config => {
    const token = getToken()
    const userId = getUserId()
    
    if (token && userId) {
        config.headers['X-User-Id'] = userId
        config.headers['X-Auth-Token'] = token
    }

    console.log("request config", config)
    return config
}, error => {
    console.log("Request Error", error)
    return Promise.reject(error)
})


service.interceptors.response.use(response => {
    const responseData = response.data

    if (response.status != 200) {
        console.log("Response Error 1", responseData.message)
        return Promise.reject(new Error(responseData.message || "Response Error"))
    } else {
        return responseData
    }
}, error => {
    console.log("Response Error 2", error)
    return Promise.reject(error)
})

export default service